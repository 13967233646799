export const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
  busy: "SERVER_BUSY",
};

export const chartTypeContants = {
  GAUGE: "GAUGE",
  RANGE: "RANGE"
}

export const cardType = {
  type1: "fire_card_1",
  type2: "fire_card_2",
  type3: "fire_card_3",
  type4: "veg_card_1",
  type5: "veg_card_2",
  type6: "veg_card_3",
  type7: "fire_live_card_1",
  type8: "fire_live_card_2",
  type9: "fire_live_card_3"
}

export const alertError = {
  uiValidation: "UIVALIDATION",
  emailExists: "EMAILEXISTS",
  serverValidation: "SERVERVALIDATION",
  unknown: "UNKNOWN"
}
