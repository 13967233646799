import { useTranslation } from "react-i18next";
import SearchAutoComplete from "../../SearchAutoComplete";

const Welcome = () => {
  const { t } = useTranslation();

  // const onClickHandler = () => {
  //   const el = document.getElementById('record-section');
  //   el && el.scrollIntoView();
  // }

  return (
    <section className="section1 hero is-primary is-fullheight intro-bg">
      <div className="hero-body has-text-centered is-flex">
        <div className="container">
          <p className="
                welcome-header1 has-text-centered
                is-size-4-mobile 
                is-size-4-touch 
                is-size-1-tablet
                has-text-weight-light
                is-size-1-desktop 

                is-size-1-widescreen 
                is-size-4-fullhd">
            {t("landingPage.section1.text1")}
          </p>
          <br />
          <p className="
                  welcome-header2 has-text-centered
                  has-text-weight-bold
                  is-size-6-mobile 
                  is-size-6-touch 
                  is-size-1-tablet 

                  is-size-4-desktop 
                  
                  is-size-4-widescreen 
                  is-size-2-fullhd">
            {t("landingPage.section1.text21")}
            <br />
            {t("landingPage.section1.text22")}
            {/* <Trans i18nKey="landingPage.section1.text2"/> */}
          </p>
          <p className="
                  welcome-header3 desc-text my-5 has-text-centered
                  has-text-weight-light
                  is-size-6-mobile 
                  is-size-6-touch 
                  is-size-1-tablet 

                  is-size-4-desktop 
                  
                  is-size-4-widescreen 
                  is-size-7-fullhd">
            {t("landingPage.section1.text31")}
            <br />
            {t("landingPage.section1.text32")}
            {/* <Trans i18nKey="landingPage.section1.text2"/> */}
          </p>
          <SearchAutoComplete showButton={true} placeName={""} width={"50%"} />
          {/* <span className="icon is-size-4 mt-6 is-clickable" onClick={onClickHandler}>
                <i className="ri-arrow-down-s-line ri-lg"></i>
              </span>  */}
        </div>
      </div>
    </section>
  )
}

export default Welcome;