import { useState, useRef } from "react";
import { TailSpin } from "react-loader-spinner";
import { apiStatusConstants } from "../../constants";
import { SearchAutoCompletePropsType, inputStateType } from "../../model";
import SuggestionItem from "../SuggestionItem";
import config from "../../config";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./index.css";

const SearchAutoComplete = ({
  showButton,
  placeName,
  width
}: SearchAutoCompletePropsType) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [input, setInput] = useState<string>(placeName);
  const [apiState, setApiState] = useState<inputStateType>({
    apiStatus: "INITIAL",
    suggestions: "",
    error: "",
  });

  const inputRef = useRef<HTMLDivElement>(null);

  const inputChangeHandler = async (e: any) => {
    setInput(e.target.value);
    if (e.target.value !== "") {
      setApiState((prevState) => ({
        suggestions: "",
        error: "",
        apiStatus: apiStatusConstants.inProgress,
      }));
      try {
        const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.target.value}}.json?country=fr&limit=10&access_token=${config.mapboxToken}`;
        const response = await fetch(apiUrl);
        const results = await response.json();
        setApiState((prevState) => ({
          suggestions: results.features,
          apiStatus: apiStatusConstants.success,
          error: "",
        }));
      } catch (error) {
        setApiState((prevState) => ({
          suggestions: "",
          error: error,
          apiStatus: apiStatusConstants.failure,
        }));
      }
    } else {
      setApiState((prevState) => ({
        suggestions: "",
        apiStatus: apiStatusConstants.initial,
        error: "",
      }));
    }
  };

  const renderSuggestionDetailView = () => {
    if (apiState?.suggestions.length > 0) {
      return (
        <div
          style={{ width: `${inputRef?.current?.offsetWidth}px`, position: "absolute" }}
          className="is-flex is-flex-direction-column is-justify-content-flex-start suggestions-container"
        >
          <hr className="suggestion-separator" />
          {apiState.suggestions.map((suggestion: any) => (
            <SuggestionItem
              key={suggestion.id}
              suggestion={suggestion}
              setInput={setInput}
              setApiState={setApiState}
            />
          ))}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderSuggestionLoadingView = () => (
    <div
      style={{
        width: `${inputRef?.current?.offsetWidth}px`,
        height: `${Number(inputRef?.current?.offsetHeight) * 3}px`, position: "absolute"
      }}
      className="mb-5 is-flex is-flex-direction-column is-justify-content-center is-align-items-center suggestions-container"
    >
      <TailSpin
        height="40"
        width="40"
        color="#006CD7"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );

  const renderSuggestionFailureView = () => (
    <div
      style={{ width: `${inputRef?.current?.offsetWidth}px` }}
      className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center suggestions-container"
    >
      <h1 className="title has-text-black">
        Something went wrong while fetching suggestions
      </h1>
    </div>
  );

  const renderSuggestionsView = () => {
    switch (apiState.apiStatus) {
      case apiStatusConstants.success:
        return renderSuggestionDetailView();
      case apiStatusConstants.failure:
        return renderSuggestionFailureView();
      case apiStatusConstants.inProgress:
        return renderSuggestionLoadingView();
      default:
        return null;
    }
  };

  const searchButtonClickHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.push("/search-details", {
      suggestion: apiState.suggestions[0],
    });
  };

  const isSearchButtonActive = () => {
    return !(
      input === "" ||
      !apiState?.suggestions ||
      apiState?.suggestions.length === 0
    );
  };

  return (
    <div className="searchbar-container is-flex is-align-items-center" style={{width}}>
      <div
        
        style={{ position: "relative", flex:1 }}
      >
        <div
          className="input-container"
          ref={inputRef}
          style={
            (input !== "" &&
              apiState?.suggestions &&
              apiState?.suggestions.length > 0) ||
            apiState.apiStatus === apiStatusConstants.inProgress
              ? {
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderTopLeftRadius: "3px",
                  borderTopRightRadius: "3px",
                }
              : {}
          }
        >
          <span className="icon">
            <i className="ri-search-line ri-lg"></i>
          </span>
          <input
            placeholder={`${t("searchField.placeholder")}`}
            value={input}
            onChange={inputChangeHandler}
            type="text"
            className="input-element"
          />
          {input === "" && (
            <span className="icon is-clickable tooltip" id="infoIcon">
              <i className="ri-information-line ri-lg"></i>
              <span className="tooltiptext">
                <div className="is-flex">
                  <span className="icon has-text-info">
                    <i className="ri-information-fill ri-lg"></i>
                  </span>
                  <span className="ml-3">{t("searchField.infoText")}</span>
                </div>
              </span>
              <span className="arrow arrow-top"></span>
            </span>
          )}
          {input !== "" && (
            <span
              className="icon is-clickable"
              onClick={() => {
                setInput("");
                setApiState((prevState) => ({
                  suggestions: "",
                  apiStatus: apiStatusConstants.initial,
                  error: "",
                }));
              }}
            >
              <i className="ri-close-line ri-lg"></i>
            </span>
          )}
        </div>
        {renderSuggestionsView()}
      </div>
      {showButton && (
        <div className="column is-3">
          <button
            disabled={!isSearchButtonActive()}
            style={{
              width: "100%",
              height: "56px",
              backgroundColor: "#000089",
              color: "#fff",
              border: "none" 
            }}
            className={`search-btn button py-0
            is-size-6-touch 
            is-size-4-tablet 
            is-size-5-desktop 

            is-size-5-widescreen 
            is-size-7-fullhd`}
            onClick={searchButtonClickHandler}
          >
            {t("searchField.text")}
            {/* <span className="icon is-size-8 is-size-9-mobile pl-5 is-pulled-right pt-1">
              <i className="ri-arrow-right-s-line ri-lg"></i>
            </span> */}
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchAutoComplete;
