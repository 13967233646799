import "./index.css";
import { loginStateType } from "../../model";
import { useEffect, useState } from "react";
import { apiStatusConstants } from "../../constants";
import { TailSpin } from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Welcome, Records, Outlook, Features2024, Features, Search, Approach } from "./sections";

import Footer from "../Footer";
import Header from "../Header";

const Home = () => {
  const { t } = useTranslation();
  const [apiState, setApiState] = useState<loginStateType>({
    apiStatus: apiStatusConstants.initial,
    data: "",
    error: "",
  });

  const [modelType, setModelType] = useState<number>(1)

  useEffect(() => {
    const userJwtToken: string = JSON.parse(
      sessionStorage.getItem("userJwtToken") || '""'
    );
    setApiState({
      apiStatus: apiStatusConstants.inProgress,
      data: "",
      error: "",
    });
    const apiCallFunction = async () => {
      const payload = {};
      const url =
        process.env.REACT_APP_AUTH_API_ENDPOINT ||
        "https://wildfire.api.kayrros.org/login/";
      const options: any = {
        headers: {
          Authorization: `Bearer ${userJwtToken}`,
        },
        method: "POST",
        body: JSON.stringify(payload),
      };
      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          setApiState({
            apiStatus: apiStatusConstants.success,
            data: data,
            error: "",
          });
        } else if (response.status === 401) {
          console.error("Invalid Token", response.status);
          setApiState({
            apiStatus: apiStatusConstants.failure,
            data: "",
            error: "Invalid Token",
          });
        } else {
          console.error("Service Unavailable", response.status);
          setApiState({
            apiStatus: apiStatusConstants.busy,
            data: "",
            error: "Service Unavailable",
          });
        }
      } catch (error) {
        console.error(error);
        setApiState({
          apiStatus: apiStatusConstants.failure,
          data: "",
          error: "Invalid Token",
        });
      }
    };
    if (userJwtToken) {
      apiCallFunction();
    } else {
      console.error("No Token is Present");
      setApiState({
        apiStatus: apiStatusConstants.failure,
        data: "",
        error: "No Token is Present",
      });
    }
  }, []);

  const renderHomePageLoadingView = () => (
    <div className="bg-container">
      <div
        style={{ height: "100vh" }}
        className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
      >
        <TailSpin
          height="80"
          width="80"
          color="#006CD7"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  );

  // const modelButtonClickHandler = () => {
  //   setModelType(2)
  // }

  const renderModelOptions = () => {
    let model1Class, model2Class, title
    if (modelType === 1) {
      model1Class = 'search-button button-active'
      model2Class = 'button-inactive'
      title = t("landingPage.modelToggleSection.button1")
    }
    else {
      model2Class = 'search-button button-active'
      model1Class = 'button-inactive'
      title = t("landingPage.modelToggleSection.button2")
    }
    return (
      <div className="model-toggle-container is-flex is-flex-direction-column">
        <div className="is-justify-content-center is-flex" >
          <button onClick={() => setModelType(1)}
            className={`model-button ${model1Class}`}>{`${t("landingPage.modelToggleSection.button1")}`}</button>
          <button onClick={() => setModelType(2)}
            className={`model-button ${model2Class}`}>{t("landingPage.modelToggleSection.button2")}</button>
        </div>
        <p className="
                  title mt-5
                  has-text-weight-bold
                  is-size-6-mobile 
                  is-size-6-touch 
                  is-size-1-tablet 

                  is-size-4-desktop 
                  
                  is-size-4-widescreen 
                  is-size-4-fullhd model-options-title">{title}</p>
      </div>
    )
  }

  const renderHomePageSuccessView = () => {
    return (
      <div
        style={{ position: 'relative', overflowY: 'hidden' }}
        className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-stretch"
      >
        {/* Navbar */}
        <Header showLogo={true} />

        <div style={{ flex: 1 }}>
          {/* Welcome screen */}
          <Welcome />

          {/* Records screen */}
          <Records />

          {/* Approach screen */}
          <Approach />

          {/* Outlook and Potential screen */}
          <Outlook />

          {/* Solution Model screen */}
          {/* <Model /> */}

          {renderModelOptions()}

          {/* Model Features */}
          {modelType === 1 ? <Features2024 /> : <Features />}

          {/* Search address */}
          <Search />

          {/* Footer */}
          <Footer />
        </div>


      </div>
    );
  };

  const renderHomePageView = () => {
    switch (apiState.apiStatus) {
      case apiStatusConstants.success:
        return renderHomePageSuccessView();
      case apiStatusConstants.failure:
        sessionStorage.clear();
        window.location.replace("https://smartservices.axaxl.com/");
        return null;
      case apiStatusConstants.busy:
        sessionStorage.clear();
        return <Redirect to="/error" />;
      case apiStatusConstants.inProgress:
        return renderHomePageLoadingView();
      case apiStatusConstants.initial:
        return renderHomePageLoadingView();
      default:
        return null;
    }
  };

  return renderHomePageView();
};

export default Home;
