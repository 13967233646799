import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import "./index.css";
import Header from "../Header";

const LegalPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header showLogo={true} style={{ color: "#26292C" }} />
      <section
        className="legal-wrapper py-6 my-6"
        style={{ paddingLeft: "320px", paddingRight: "320px" }}
      >
        <p
          className="title my-6 
        is-size-4-touch 
        is-size-1-tablet 
        is-size-6-desktop 

        is-size-3-widescreen 
        is-size-3-fullhd"
        >
          {t("legalPage.title")}
        </p>
        <div
          className="subtitle has-text-grey
        is-size-4-touch 
        is-size-1-tablet 
        is-size-11-desktop 

        is-size-8-widescreen 
        is-size-8-fullhd
        has-text-justified"
        >
          <p className="my-4">{t("legalPage.desc.p1")}</p>
          <p className="my-4">{t("legalPage.desc.p2")}</p>
          <p className="my-4">{t("legalPage.desc.p3")}</p>
          <p className="my-4">{t("legalPage.desc.p4")}</p>
          <p className="my-4">{t("legalPage.desc.p5")}</p>
          <p className="my-4">{t("legalPage.desc.p6")}</p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LegalPage;
