import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import "./index.css";
import { loginStateType } from "../../model";
import { alertError, apiStatusConstants, cardType } from "../../constants";
import MetricCard from "../MetricCard";
import { TailSpin } from "react-loader-spinner";
import Carousel from "../Carousel";
import { useTranslation } from "react-i18next";
import MapContainer from "../MapContainer";
import SearchAutoComplete from "../SearchAutoComplete";
import cardFire1 from "../../assets/initial_cards/fire-1.png";
import cardFire2 from "../../assets/initial_cards/fire-2.png";
import cardFire3 from "../../assets/initial_cards/fire-3.png";
import cardVeg1 from "../../assets/initial_cards/veg-1.png";
import cardVeg2 from "../../assets/initial_cards/veg-2.png";
import cardVeg3 from "../../assets/initial_cards/veg-3.png";
import cardLive from "../../assets/initial_cards/live.png";
import checkIcon from "../../assets/CheckCircle.svg";
import arrowIcon from "../../assets/arrow.svg";
import HeaderDashboard from "../HeaderDashboard";
import Joyride from 'react-joyride';
import Report from "../Report";

const alertActivated = true
const isValidEmail = /^[\w.-]+@([\w-]+\.)+[\w]{2,}$/g;

const steps = [
  {
    target: '.first-step',
    content: '',
    disableBeacon: true,
  },
  {
    target: '.second-step',
    content: '',
    disableBeacon: true,
  },
  {
    target: '.third-step',
    content: '',
    disableBeacon: true,
  },
  {
    target: '.fourth-step',
    content: '',
    disableBeacon: true,
  },
  {
    target: '.fifth-step',
    content: '',
    disableBeacon: true,
  },
  {
    target: '.sixth-step',
    content: '',
    disableBeacon: true,
  },
  {
    target: '.seventh-step',
    content: '',
    disableBeacon: true,
  }
]

const SearchDetails = () => {
  const userJwtToken: string = JSON.parse(
    sessionStorage.getItem("userJwtToken") || '""'
  );

  const [popupInfo, setPopupInfo] = useState<any>({
    value: false,
    cb: null
  })
  const history: any = useHistory();
  const { t } = useTranslation();

  const [loginApiState, setLoginApiState] = useState<loginStateType>({
    apiStatus: apiStatusConstants.initial,
    data: "",
    error: "",
  });

  const [enableOverlay, setEnableOverlay] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [downloadSelected, setDownloadSelected] = useState<boolean>(false);
  const [liveModeEnabled, setLiveModeEnabled] = useState<boolean>(false);
  const [isForestFireMode, setForestFireMode] = useState<boolean>(true);

  const [runTour, setRunTour] = useState<boolean>(true)

  const [vegetationData, setVegetationData] = useState<any>({})
  const [wildfireData, setWildfireData] = useState<any>({})

  const [timeSeriesData, setTimeSeriesData] = useState<any>({})
  const [burningAreaData, setBurningAreaData] = useState<any>({})
  const [liveMapData, setLiveMapData] = useState<any>({})
  const [input, setInput] = useState("");
  const [alertObject, setAlertObject] = useState<any>({
    // invalidEmail: false,
    requested: false,
    error: null
  })

  const CustomTooltip = ({
    index,
    size,
    primaryProps,
    tooltipProps,
    backProps,
    closeProps
  }: any) => {
    const i = index + 1
    return (<div {...tooltipProps} className="tour-container">

      <span onClick={() => {
        setRunTour(false)
      }}
        className="close-icon icon is-medium is-clickable"
      >
        <i className="ri-close-line ri-1x"></i>
      </span>

      <p className="tour-title mt-5 mb-4">{i}. {t(`dashboard.tour.title${i}`)}</p>
      <p className="tour-subtitle mb-4">{t(`dashboard.tour.desc${i}`)}</p>
      <div className="subtitle is-flex is-justify-content-center is-align-items-center">

        <span  {...backProps}
          className="is-flex is-justify-content-center icon is-medium is-clickable"
        >
          <i className="ri-arrow-left-s-line ri-1x"></i>
        </span>

        <div className="is-size-8-fullhd">{i}/{size}</div>
        <span  {...primaryProps}
          className="is-flex is-justify-content-center icon is-medium is-clickable"
        >
          <i className="ri-arrow-right-s-line ri-1x"></i>
        </span>
      </div></div>)
  }

  // Token validation
  useEffect(() => {
    const userJwtToken: string = JSON.parse(
      sessionStorage.getItem("userJwtToken") || '""'
    );

    setLoginApiState({
      apiStatus: apiStatusConstants.inProgress,
      data: "",
      error: "",
    });
    const apiCallFunction = async () => {
      const payload = {};
      const url =
        process.env.REACT_APP_AUTH_API_ENDPOINT ||
        "https://wildfire.api.kayrros.org/login/";
      const options: any = {
        headers: {
          Authorization: `Bearer ${userJwtToken}`,
        },
        method: "POST",
        body: JSON.stringify(payload),
      };
      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          setLoginApiState({
            apiStatus: apiStatusConstants.success,
            data: data,
            error: "",
          });
        } else if (response.status === 401) {
          console.error("Invalid Token", response.status);
          setLoginApiState({
            apiStatus: apiStatusConstants.failure,
            data: "",
            error: "Invalid Token",
          });
        } else {
          console.error("Service Unavailable", response.status);
          setLoginApiState({
            apiStatus: apiStatusConstants.busy,
            data: "",
            error: "Service Unavailable",
          });
        }
      } catch (error) {
        console.error(error);
        setLoginApiState({
          apiStatus: apiStatusConstants.failure,
          data: "",
          error: "Invalid Token",
        });
      }
    };
    if (userJwtToken) {
      apiCallFunction();
    } else {
      console.error("No Token is Present");
      setLoginApiState({
        apiStatus: apiStatusConstants.failure,
        data: "",
        error: "No Token is Present",
      });
    }
  }, [userJwtToken]);

  useEffect(() => {
    const suggestionData = history?.location?.state?.suggestion;
    const requests = []
    if (suggestionData) {
      const locationDetails = {
        longitude: suggestionData.center[0],
        latitude: suggestionData.center[1],
        address: `${suggestionData?.place_name}`,
      }
      requests.push({
        url: (process.env.REACT_APP_RISK_SCORE_API_ENDPOINT ||
          "https://wildfire.dev.api.kayrros.org/risk_scores?buffer=30000"),
        options: {
          headers: {
            Authorization: `Bearer ${userJwtToken}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(locationDetails),
        },
        handler: setWildfireData
      },
        {
          url: (process.env.REACT_APP_RISK_SCORE_API_ENDPOINT_OLD ||
            "https://wildfire.dev.api.kayrros.org/risk_scores_old?buffer=30000"),
          options: {
            headers: {
              Authorization: `Bearer ${userJwtToken}`,
              accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(locationDetails),
          },
          handler: setVegetationData
        })
      requests.push({
        url: process.env.REACT_APP_TIME_SERIES_API_ENDPOINT ||
          "https://wildfire.dev.api.kayrros.org/timeseries?month_start=4",
        options: {
          headers: {
            Authorization: `Bearer ${userJwtToken}`,
          },
          method: "GET"
        },
        handler: setTimeSeriesData
      },
        {
          url: process.env.REACT_APP_DAILY_TIME_SERIES_API_ENDPOINT ||
            "https://wildfire.dev.api.kayrros.org/daily_burning_area",
          options: {
            headers: {
              Authorization: `Bearer ${userJwtToken}`,
            },
            method: "GET"
          },
          handler: setBurningAreaData
        },
        {
          url: process.env.REACT_APP_LIVE_MAP_API_ENDPOINT ||
            "https://wildfire.dev.api.kayrros.org/france_fires",
          options: {
            headers: {
              Authorization: `Bearer ${userJwtToken}`,
            },
            method: "GET"
          },
          handler: setLiveMapData
        })
    }

    requests.map(invokeApi)
  }, [history?.location?.state?.suggestion, userJwtToken]);

  useEffect(() => {
    setEnableOverlay(popupInfo.value)
    if (popupInfo.cb) popupInfo.cb(popupInfo.value)
  }, [popupInfo])

  const invokeApi = (request: any) => {
    fetch(request.url, request.options).then((res) => res.json()).then((res) => request.handler(res))
  }

  const handleDownloadPDF = async () => {
    if (
      loginApiState.apiStatus === apiStatusConstants.success &&
      suggestionData
    ) {
      setDownloadSelected(true)
    }
  }

  const renderSearchPageLoadingView = () => {
    return (
      <div
        style={{ height: "calc(100vh - 250px)", width: "100%" }}
        className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
      >
        <TailSpin
          height="80"
          width="80"
          color="#006CD7"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  const renderSearchPageInitialView = () => {
    let card1Image, card2Image, card3Image
    if (isForestFireMode) {
      if (liveModeEnabled) {
        card1Image = card2Image = card3Image = cardLive
      }
      else {
        card1Image = cardFire1
        card2Image = cardFire2
        card3Image = cardFire3
      }
    }
    else {
      card1Image = cardVeg1
      card2Image = cardVeg2
      card3Image = cardVeg3
    }
    return (
      <div className="columns is-variable is-4">
        <div className="column is-6">
          <div
            className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "11px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <span className="icon is-large">
              <i className="ri-image-line ri-2x"></i>
            </span>
          </div>
        </div>
        <div className="column is-6">
          <div
            className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start is-flex-wrap-wrap"
            style={{ height: "100%", width: "100%", gap: "20px 20px" }}
          >
            <div
              style={{
                height: "calc(50% - 10px)",
                width: "calc(50% - 10px)",
                position: "relative",
              }}
            >
              <img
                className=""
                src={card1Image}
                alt="card 1"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                height: "calc(50% - 10px)",
                width: "calc(50% - 10px)",
                position: "relative",
              }}
            >
              <img
                className=""
                src={card2Image}
                alt="card 2"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                height: "calc(50% - 10px)",
                width: "calc(50% - 10px)",
                position: "relative",
              }}
            >
              <img
                className=""
                src={card3Image}
                alt="card 3"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <div
              style={{
                height: "calc(50% - 10px)",
                width: "calc(50% - 10px)",
                position: "relative",
              }}
            >
              <Carousel />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const liveOptionClicked = () => {
    setLiveModeEnabled(!liveModeEnabled)
  }

  const renderSearchDetailBody = () => {
    const suggestionData = history?.location?.state?.suggestion;
    const { cardData, mapData } = getCardsAndMapData(isForestFireMode, liveModeEnabled, wildfireData, vegetationData)
    return (
      <div className="is-flex is-justify-content-space-between">
        <div className="step-container body-item">
          <div className="third-step"></div>
          {mapData && mapData.data && <MapContainer togglePopup={togglePopup} liveModeEnabled={liveModeEnabled} isForestFireMode={isForestFireMode}
            suggestionData={suggestionData}
            data={mapData}
          />}
        </div>
        <div className="step-container body-item">
          <div className="fourth-step"></div>
          <div
            className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start is-flex-wrap-wrap"
            style={{ height: "100%", width: "100%", gap: "20px 20px" }}
          >
            <div className="card-container"
              style={{
                width: "calc(50% - 10px)",
              }}
            >
              <MetricCard liveModeEnabled={liveModeEnabled} data={cardData[0]} apiStatus={apiStatusConstants.success}></MetricCard>
            </div>
            <div className="card-container"
              style={{
                width: "calc(50% - 10px)",
              }}
            >
              <MetricCard liveModeEnabled={liveModeEnabled} data={cardData[1]} apiStatus={apiStatusConstants.success}></MetricCard>
            </div>
            <div className="card-container"
              style={{
                width: "calc(50% - 10px)",
              }}
            >
              <MetricCard liveModeEnabled={liveModeEnabled} data={cardData[2]} apiStatus={apiStatusConstants.success}></MetricCard>
            </div>
            <div className="card-container"
              style={{
                width: "calc(50% - 10px)",
              }}
            >
              <Carousel />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getCardsAndMapData = (isForestFireMode: boolean, liveModeEnabled: boolean, wildfireData: any, vegetationData: any) => {
    if (isForestFireMode) {
      if (liveModeEnabled) {
        const series1: any[] = []
        const series2: any[] = []
        const series3: any[] = []
        const series4: any[] = []
        const series5: any[] = []
        const series6: any[] = []
        if (timeSeriesData) {
          Object.keys(timeSeriesData).forEach((key) => {
            series1.push([parseInt(key), parseInt(timeSeriesData[key]['burned_ha_2023'])])
            const value2024 = parseInt(timeSeriesData[key]['burned_ha_2024'])
            if (value2024 !== 0) series2.push([parseInt(key), value2024]);

            const nbFires2024 = parseInt(timeSeriesData[key]['nb_fires_2024'])
            series3.push([parseInt(key), parseInt(timeSeriesData[key]['nb_fires_2023'])])
            if (nbFires2024 !== 0)
              series4.push([parseInt(key), nbFires2024])
          })
        }
        if (burningAreaData) {
          Object.keys(burningAreaData).forEach((key) => {
            series5.push([parseInt(key), parseInt(burningAreaData[key]['2023'])])
            const ba2024 = parseInt(burningAreaData[key]['2024'])
            if (!isNaN(ba2024))
              series6.push([parseInt(key), ba2024])
          })
        }
        return {
          mapData: {
            liveModeEnabled,
            data: liveMapData
          },
          cardData: [
            {
              type: cardType.type8,
              value: [
                series3.sort(sortAsc),
                series4.sort(sortAsc)
              ]
            },
            {
              type: cardType.type7,
              value: [
                series5.sort(sortAsc),
                series6.sort(sortAsc)
              ]
            },
            {
              type: cardType.type9,
              value: [
                series1.sort(sortAsc),
                series2.sort(sortAsc)
              ]
            }
          ]
        }
      }
      else {
        // Fire risk dashboard
        if (wildfireData && wildfireData.length > 0) {
          return {
            mapData: {
              liveModeEnabled,
              data: {
                bounds: wildfireData[4],
                image: wildfireData[0]
              }
            },
            cardData: [
              {
                type: cardType.type1,
                value: wildfireData[1]
              },
              {
                type: cardType.type2,
                value: wildfireData[3]
              },
              {
                type: cardType.type3,
                value: wildfireData[2]
              }
            ]
          }
        }
        else {
          return {
            mapData: {},
            cardData: [
              {
                type: cardType.type1,
                value: 0
              },
              {
                type: cardType.type2,
                value: 0
              },
              {
                type: cardType.type3,
                value: 0
              }
            ]
          }
        }
      }
    }
    else {
      if (vegetationData && vegetationData.length > 0) {
        return {
          mapData: {
            liveModeEnabled,
            data: vegetationData[0],
            value: vegetationData[1]
          },
          cardData: [
            {
              type: cardType.type4,
              value: vegetationData[1]
            },
            {
              type: cardType.type5,
              value: vegetationData[3]
            },
            {
              type: cardType.type6,
              value: vegetationData[2]
            }
          ]
        }
      }
      else {
        return {
          mapData: {},
          cardData: [
            {
              type: cardType.type4,
              value: 0
            },
            {
              type: cardType.type5,
              value: 0
            },
            {
              type: cardType.type6,
              value: 0
            }
          ]
        }
      }
    }
  }

  const renderSearchPageView = () => {
    const suggestionData = history?.location?.state?.suggestion;
    if (
      loginApiState.apiStatus === apiStatusConstants.success &&
      suggestionData
    ) {
      return renderSearchDetailBody();
    } else if (
      loginApiState.apiStatus === apiStatusConstants.success
    ) {
      return renderSearchPageInitialView();
    } else if (
      loginApiState.apiStatus === apiStatusConstants.failure
    ) {
      sessionStorage.clear();
      window.location.replace("https://smartservices.axaxl.com/");
      return null;
    } else if (
      loginApiState.apiStatus === apiStatusConstants.busy
    ) {
      sessionStorage.clear();
      return <Redirect to="/error" />;
    } else {
      return renderSearchPageLoadingView();
    }
  };

  const sortAsc = (a: any, b: any) => {
    return (a[0] - b[0])
  }

  const renderTourGuide = () => {
    return (
      <div>
        <Joyride
          run={runTour}
          steps={steps}
          tooltipComponent={CustomTooltip}
          // disableCloseOnEsc={true}
          disableOverlay
          continuous
          disableOverlayClose
          styles={{
            options: {
              arrowColor: '#2F3337',
              backgroundColor: '#2F3337',
              primaryColor: '#000',
              textColor: '#F5F5F5',

            },
          }} />
      </div>
    );
  }

  const validateEmail = (e: any) => {
    if (input && input.match(isValidEmail)) {
      const locationDetails = {
        "email": input,  // validate email format
        longitude: suggestionData.center[0],
        latitude: suggestionData.center[1],
        address: `${suggestionData?.place_name}`,
      }
      const url = (process.env.REACT_APP_EMAIL_ALERT ||
        "https://wildfire.dev.api.kayrros.org/alerts")

      const options = {
        headers: {
          Authorization: `Bearer ${userJwtToken}`,
          accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(locationDetails),
      }
      setAlertObject({
        // invalidEmail: false,
        requested: false,
        error: null
      })
      fetch(url, options)
        .then((res) => {
          switch (res.status) {
            case 204:
              setAlertObject({
                // invalidEmail: false,
                requested: true,
                error: null
              })
              return res.json();
            case 400:
              setAlertObject({
                // invalidEmail: true,
                requested: true,
                error: alertError.serverValidation
              })
              throw new Error(`Error code ${res.status}`)
            case 403:
              setAlertObject({
                // invalidEmail: true,
                requested: true,
                error: alertError.emailExists
              })
              throw new Error(`Error code ${res.status}`)
            default:
              setAlertObject({
                // invalidEmail: true,
                requested: true,
                error: alertError.unknown
              })
              throw new Error(`Error code ${res.status}`)
          }
        })
        .then((res) => { })
        .catch((error) => { })
    } else {
      setAlertObject({
        // invalidEmail: true,
        requested: false,
        error: alertError.uiValidation
      })
    }
  }

  const renderAlertComponent = () => {
    if (alertActivated) {
      if (alertObject.requested && alertObject.error === null) {

          return (<div className="alert-container-complete">
            <span className="alert-arrow"><img src={arrowIcon} alt="arrow-icon" /></span>
            <div className="alert-body p-5">
              <div className="alert-title">{t(`dashboard.alert.title`)}</div>
              <div className="alert-completedTitle-container mt-4">
                <img
                  src={checkIcon}
                  alt="check"
                />
                <div className="alert-completedTitle pl-2">{t(`dashboard.alert.completedTitle`)}</div>
              </div>
              <div className="alert-completedSubTitle mt-4">{t(`dashboard.alert.completedSubTitle`)}</div>
            </div></div>)

      }
      let classname = "alert-email is-flex is-align-items-center px-4"
      let errorMessage = null
      if (alertObject.error === alertError.emailExists) {
        classname += " alert-email-exist-error"
        errorMessage = <div className="alert-email-exits-text mt-3">{t(`dashboard.alert.emailExistsErrorMessage`)}</div>
      }
      else if (alertObject.error === alertError.serverValidation || alertObject.error === alertError.uiValidation) {
        classname += " alert-email-error"
        errorMessage = <div className="alert-email-incorrect-text mt-3">{t(`dashboard.alert.incorrectEmailText`)}</div>
      }
      else if (alertObject.error === alertError.unknown) {
        console.log('unknown error')
      }
      return (<div className="alert-container">
        <span className="alert-arrow"><img src={arrowIcon} alt="arrow-icon" /></span>
        <div className="alert-body p-5">
          <div className="alert-title">{t(`dashboard.alert.title`)}</div>
          <div className="alert-subtitle mt-4">
            <span>{t(`dashboard.alert.subtitle1`)}</span>&nbsp;
            <span className="alert-subtitle-bold">{t(`dashboard.alert.subtitle2`)}</span>&nbsp;
            <span>{t(`dashboard.alert.subtitle3`)}</span>
          </div>
          <div className="alert-form is-flex is-justify-content-left is-flex-direction-column mt-4">
            <div className="alert-email-text">{t(`dashboard.alert.emailTitle`)}</div>
            <div className={classname}>
              <i className="ri-mail-line ri-lg px-2"></i>
              <input
                value={input} onChange={(e) => setInput(e.target.value)} className="alert-input" placeholder={t(`dashboard.alert.emailHint`).toString()}></input>
            </div>
            {errorMessage && errorMessage}
            <div onClick={validateEmail} className="is-clickable alert-submit-button mt-4 is-flex is-align-items-center is-justify-content-center">{t(`dashboard.alert.button`)}</div>
          </div>
        </div>
      </div>)
    }
    else {
      return (<div className="alert-container-inactive">
        <span className="alert-arrow"><img src={arrowIcon} alt="arrow-icon" /></span>
        <div className="alert-body is-flex is-flex-direction-column is-align-items-center is-justify-content-center p-5">
          <div className="alert-disabled-title">{t(`dashboard.alert.disabledTitle`)}</div>
          <div className="alert-disabled-subtitle mt-4">{t(`dashboard.alert.disabledSubTitle`)}</div>
          <div className="is-clickable alert-disabled-submit-button mt-4 is-flex is-align-items-center is-justify-content-center">{t(`dashboard.alert.disabledButton`)}</div>
        </div>

      </div>)
    }
  }

  const togglePopup = (value: boolean, cb: any = null) => {
    setPopupInfo({
      value,
      cb: cb ?? null
    })
  }

  const suggestionData = history?.location?.state?.suggestion;
  const liveButtonClassName = liveModeEnabled ? 'live-icon-container' : (isForestFireMode ? 'live-icon-container-inactive' : 'live-icon-container-disabled')
  return (
    <div style={{ position: 'relative', overflow: 'hidden' }} className="bg-search-details-container is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-stretch">
      <HeaderDashboard runTour={runTour} toggleRunTour={setRunTour} />
      <div className="dashboard-body">
        <div className="dashboard-header is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center px-6">
          <div className="dashboard-header-item">
            <SearchAutoComplete
              width={"100%"}
              showButton={false}
              placeName={suggestionData?.place_name || ""}
            />
            <div className="first-step"></div>
          </div>
          <div className="dashboard-header-item is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
            <div className="header-buttons is-flex is-flex-direction-row is-flex is-justify-content-start">
              <div
                className="step-container is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                <div className="second-step"></div>
                <button
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: isForestFireMode ? "#F3F5F8" : "#FFF",
                    color: !isForestFireMode ? "#94A3B8" : "#000089",
                    border: "none"
                  }}
                  className={`search-btn button py-0 mx-2
            is-size-6-touch 
            is-size-4-tablet 
            is-size-5-desktop 
            has-text-weight-semibold

            is-size-5-widescreen 
            is-size-8-fullhd`}
                  onClick={() => { setForestFireMode(true); }}
                >
                  <span className="icon mr-2">
                    <i className="ri-fire-fill ri-lg"></i>
                  </span>
                  {t("dashboard.buttons.button1")}
                </button>
                <button
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: isForestFireMode ? "#FFF" : "#F3F5F8",
                    color: isForestFireMode ? "#94A3B8" : "#000089",
                    border: "none"
                  }}
                  className={`search-btn button py-0
            is-size-6-touch 
            is-size-4-tablet 
            is-size-5-desktop 
            has-text-weight-semibold

            is-size-5-widescreen 
            is-size-8-fullhd`}
                  onClick={() => { setLiveModeEnabled(false); setForestFireMode(false); }}
                >
                  <span className="icon mr-2">
                    <i className="ri-leaf-fill ri-lg"></i>
                  </span>
                  {t("dashboard.buttons.button2")}
                </button>
              </div>
            </div>
            <div className="dashboard-header-item is-flex is-justify-content-end is-align-items-center">
              <div className="alert-button step-container mr-4">

                <div onClick={() => {
                  togglePopup(!showAlert, setShowAlert);
                  setAlertObject({
                    // invalidEmail: false,
                    requested: false,
                    error: null
                  });
                  setInput("") }} className={`is-clickable is-flex is-align-items-center ${showAlert ? 'alert-active' : 'alert-inactive'}`}><div className="sixth-step"></div>
                  <span className="mr-4" >
                    <i className="ri-mail-unread-fill ri-lg"></i>
                  </span><p>{t("dashboard.buttons.myAlert")}</p></div>
                {showAlert && renderAlertComponent()}
              </div>
              <button onClick={handleDownloadPDF} className="button step-container has-text-white is-size-8-fullhd search-button mr-4">
                <div className="seventh-step"></div>
                <span className="icon mr-4">
                  <i className="ri-file-download-fill ri-lg"></i>
                </span>{t("dashboard.buttons.downloadPdf")}</button>
            </div>
          </div>
        </div>
        <div className="dashboard-content is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-stretch px-6">
          <div
            className="is-flex mt-4 is-flex-direction-row is-justify-content-flex-start is-align-items-center title-text-color"
          >
            <span className="icon">
              <i className="ri-dashboard-line ri-lg"></i>
            </span>
            <p className="search-details-title1 ml-2">{t("dashboard.header.title1")}</p>
          </div>
          <div className="is-flex is-flex-direction-row is-align-items-baseline is-justify-content-space-between">
            <div className="is-flex is-flex-direction-row is-align-items-baseline mb-4">
              <p className="search-details-title">
                {suggestionData?.place_name}
                {/* {"aaaaaasakbkvwnfpwpgpwfpfgpeor;wnfoierognoeoi;geongonrfnknewnfvnflnvlnflwnvlnwlfvnlwnlfvnnvofnnrfnwnv;fnvnnfslvno;e"} */}
              </p>
              {/* {suggestionData?.properties?.address && <p className="search-details-subtitle">
                {`, ${suggestionData?.properties?.address || "-"}`}
              </p>} */}

            </div>
            {isForestFireMode && <div
              className="live-button-container ml-4 is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
              style={{ position: "relative" }}
            >
              <div
                className={`${liveButtonClassName} is-clickable step-container is-flex is-flex-direction-row is-align-items-center`}
                onClick={isForestFireMode ? liveOptionClicked : () => { }}
              >
                <div className="fifth-step"></div>
                <i className={`${liveModeEnabled ? 'ri-live-fill' : 'ri-live-line'} ri-xl`}></i>
                <p className="pl-1 is-size-6 ml-3">{t("dashboard.header.title2")}</p>
              </div>
            </div>}
          </div>
          {renderSearchPageView()}
        </div>
        {renderTourGuide()}
        <Report
          finishCb={() => { setDownloadSelected(false) }}
          trigger={downloadSelected}
          vegData={getCardsAndMapData(false, false, wildfireData, vegetationData)}
          fireData={getCardsAndMapData(true, false, wildfireData, vegetationData)}
          suggestionData={suggestionData}
        />
      </div>
      {enableOverlay && <div className="popup-overlay" onClick={() => { togglePopup(false, popupInfo.cb) }}></div>}
    </div>
  );
};

export default SearchDetails;
