// import React from "react";
import "./index.css";
import { useEffect } from "react";
import axa_logo_header from "../../assets/logos/axa_logo_header.png";
import kayrros_logo_header from "../../assets/logos/kayrros_logo_header.png";
import { NavLink } from "react-router-dom";
import SideBarGradientCard from "../SideBarGradientCard";
import { useTranslation } from "react-i18next";
import { motion as m } from "framer-motion";

const SideBar = ({ setShowSideBar, runTour, toggleRunTour }: any) => {
  const { t } = useTranslation();
  const sidebarAnimation: any = {
    hidden: {
      opacity: 0,
      x: "-50vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        type: "tween",
        ease: "anticipate",
      },
    },
    exit: {
      opacity: 0,
      x: "-50vw",
      transition: {
        duration: 0.3,
      },
    },
  };
  // const [runTour, setRunTour] = useState<boolean>(false)

  useEffect(() => {
    if (toggleRunTour)
      toggleRunTour(runTour)
  }, [runTour, toggleRunTour])

  return (
    <m.div
      variants={sidebarAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="custom-sidebar is-flex is-flex-direction-column is-justify-content-flex-start px-4 py-5"
    >
    <span
      className="is-flex is-align-items-center is-justify-content-center is-clickable close-sidebar-icon"
      onClick={() => {
        setShowSideBar(false);
      }}
    >
      <i className="ri-arrow-left-s-line ri-2x"></i>
    </span>
      <div className="is-flex is-align-items-center">
        <a
          href="https://smartservices.axaxl.com/fr/"
          target="_blank"
          rel="noreferrer"
        >
          <figure className="image axa-logo-header-container ml-4">
            <img src={axa_logo_header} alt="Axa Logo" />
          </figure>
        </a>
        <a href="https://www.kayrros.com/" target="_blank" rel="noreferrer">
          <figure className="image kayrros-logo-header-container ml-4">
            <img src={kayrros_logo_header} alt="Kayrros Logo" />
          </figure>
        </a>
      </div>
      <div className="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-align-items-stretch">
        <NavLink
          to="/"
          className={(isActive) =>
            isActive ? "selected-link" : "unselected-link"
          }
          isActive={(match, location) => {
            if (location.pathname === "/") {
              return true;
            } else {
              return false;
            }
          }}
        >
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center link-item">
            <span className="icon ml-4" style={{ fontWeight: "normal" }}>
              <i className="ri-home-4-line ri-2x"></i>
            </span>
            <p className="is-size-6 pl-4">{t("sidebar.title1")}</p>
          </div>
        </NavLink>
        <NavLink
          to="/search-details"
          className={(isActive) =>
            isActive ? "selected-link" : "unselected-link"
          }
          isActive={(match, location) => {
            if (location.pathname === "/search-details") {
              return true;
            } else {
              return false;
            }
          }}
        >
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center link-item">
            <span className="icon ml-4" style={{ fontWeight: "normal" }}>
              <i className="ri-dashboard-line ri-2x"></i>
            </span>
            <p className="is-size-6 pl-4">{t("sidebar.title2")}</p>
          </div>
        </NavLink>
        <a href="https://smartservices.axaxl.com/" className="unselected-link">
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center link-item">
            <span className="icon ml-4" style={{ fontWeight: "normal" }}>
              <i className="ri-tv-2-line ri-2x"></i>
            </span>
            <p className="is-size-6 pl-4">{t("sidebar.title3")}</p>
          </div>
        </a>
      </div>
      <div>
        {/* <SideBarImageCard /> */}
        <SideBarGradientCard />
      </div>
      <div className="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-stretch">

        {<div className="is-clickable" onClick={toggleRunTour}>
          <div className={`${runTour ? "selected-icon" : ""} ${toggleRunTour ? '' : 'greyed'} link-item is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center`}>
            <span className="icon ml-4" style={{ fontWeight: "normal" }}>
              <i className={`${runTour ? "ri-discuss-fill" : "ri-discuss-line"} ri-2x`}></i>
            </span>
            <p className="is-size-6 pl-4">{t("sidebar.title6")}</p>
          </div>
        </div>}
        <a
        target="_blank"
          href="/intro"
          className="unselected-link"
        >
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center link-item">
            <span className="icon ml-4" style={{ fontWeight: "normal" }}>
              <i className="ri-question-line ri-2x"></i>
            </span>
            <p className="is-size-6 pl-4">{t("sidebar.title7")}</p>
          </div>
        </a>
              {/* <a
                href="https://smartservices.axaxl.com/"
              >
                <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                <i className="ri-question-line ri-2x"></i>
                </span>
              </a> */}
        <div className="separator mb-4"></div>
        <NavLink
          to="/legal"
          className={(isActive) =>
            isActive ? "selected-link" : "unselected-link"
          }
          isActive={(match, location) => {
            if (location.pathname === "/legal") {
              return true;
            } else {
              return false;
            }
          }}
        >
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center link-item">
            <span className="icon ml-4" style={{ fontWeight: "normal" }}>
              <i className="ri-information-line ri-2x"></i>
            </span>
            <p className="is-size-6 pl-4">{t("sidebar.title4")}</p>
          </div>
        </NavLink>
        <NavLink
          to="/gdpr"
          className={(isActive) =>
            isActive ? "selected-link" : "unselected-link"
          }
          isActive={(match, location) => {
            if (location.pathname === "/gdpr") {
              return true;
            } else {
              return false;
            }
          }}
        >
          <div className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center link-item">
            <span className="icon ml-4" style={{ fontWeight: "normal" }}>
              <i className="ri-git-repository-private-line ri-2x"></i>
            </span>
            <p className="is-size-6 pl-4">{t("sidebar.title5")}</p>
          </div>
        </NavLink>
      </div>
    </m.div>
  );
};

export default SideBar;
