import React from "react";
import { useHistory } from "react-router-dom";
import "./index.css";
import { apiStatusConstants } from "../../constants";

const SuggestionItem = ({ suggestion, setInput, setApiState }: any) => {
  const history = useHistory();
  const clickHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setInput(suggestion.place_name);
    setApiState(() => ({
      suggestions: "",
      apiStatus: apiStatusConstants.initial,
      error: "",
    }));
    history.push("/search-details", {
      suggestion: suggestion,
    });
  };
  return (
    <div
      style={{
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: "16px",
        paddingRight: "16px",
        width: "100%",
      }}
      id={suggestion.place_name}
      className="is-clickable suggestion-item has-text-left"
      onClick={clickHandler}
    >
      <h1 className="is-size-11" style={{ color: "#5E656E" }}>
        {suggestion.place_name}
      </h1>
    </div>
  );
};

export default SuggestionItem;
