import React, { useEffect, useState } from "react";
import { loginStateType } from "../../model";
import { apiStatusConstants } from "../../constants";
import { TailSpin } from "react-loader-spinner";
import { Redirect, useParams } from "react-router-dom";

const TokenValidator = () => {
  const params: any = useParams();

  const [apiState, setApiState] = useState<loginStateType>({
    apiStatus: apiStatusConstants.initial,
    data: "",
    error: "",
  });

  useEffect(() => {
    const userJwtToken: string = params?.token || "";
    setApiState({
      apiStatus: apiStatusConstants.inProgress,
      data: "",
      error: "",
    });
    const apiCallFunction = async () => {
      const payload = {};
      const url =
        process.env.REACT_APP_AUTH_API_ENDPOINT ||
        "https://wildfire.api.kayrros.org/login/";
      const options: any = {
        headers: {
          Authorization: `Bearer ${userJwtToken}`,
        },
        method: "POST",
        body: JSON.stringify(payload),
      };
      try {
        const response = await fetch(url, options);
        if (response.ok) {
          const data = await response.json();
          sessionStorage.setItem("userJwtToken", JSON.stringify(userJwtToken));
          setApiState({
            apiStatus: apiStatusConstants.success,
            data: data,
            error: "",
          });
        } else if (response.status === 401) {
          console.error("Invalid Token", response.status);
          setApiState({
            apiStatus: apiStatusConstants.failure,
            data: "",
            error: "Invalid Token",
          });
        } else {
          console.error("Service Unavailable", response.status);
          setApiState({
            apiStatus: apiStatusConstants.busy,
            data: "",
            error: "Service Unavailable",
          });
        }
      } catch (error) {
        console.error(error);
        setApiState({
          apiStatus: apiStatusConstants.failure,
          data: "",
          error: error,
        });
      }
    };
    if (userJwtToken) {
      apiCallFunction();
    } else {
      console.error("No Token is Present");
      setApiState({
        apiStatus: apiStatusConstants.failure,
        data: "",
        error: "No Token is Present",
      });
    }
  }, [params?.token]);

  const renderValidationLoadingView = () => (
    <div className="bg-container">
      <div
        style={{ height: "100vh" }}
        className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
      >
        <TailSpin
          height="80"
          width="80"
          color="#006CD7"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  );

  const renderValidationView = () => {
    switch (apiState.apiStatus) {
      case apiStatusConstants.success:
        return <Redirect to="/" />;
      case apiStatusConstants.failure:
        sessionStorage.clear();
        window.location.replace("https://smartservices.axaxl.com/");
        return null;
      case apiStatusConstants.busy:
        sessionStorage.clear();
        return <Redirect to="/error" />;
      case apiStatusConstants.inProgress:
        return renderValidationLoadingView();
      case apiStatusConstants.initial:
        return renderValidationLoadingView();
      default:
        return null;
    }
  };

  return renderValidationView();
};

export default TokenValidator;
