import React from "react";
import Image from "../../assets/server_error.jpg";

const ServerErrorPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: `url(${Image})`,
        backgroundSize: "cover",
      }}
      className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
    >
      <div className="box has-text-centered">
        <h1 className="title has-text-dark">
          Our Servers are facing high demand right now
        </h1>
        <h1 className="title has-text-dark">We will be back soon</h1>
      </div>
    </div>
  );
};

export default ServerErrorPage;
