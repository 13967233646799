import { useTranslation, Trans } from "react-i18next";
import model_widget1 from "../../../assets/model_widget1v22.jpg"
import model_widget2 from "../../../assets/model_widget2v22.jpg";
import model_widget3 from "../../../assets/model_widget3v22.jpg";

const Features = () => {
  const { t } = useTranslation();
  return (
    <section className="divider mt-6">

      <section className="section5 feature1 pl-6 pr-6 pt-0 hero is-white ">
        <div className="image-desc-container is-flex is-justify-content-space-between is-align-items-center p-6">
          <div className="is-flex is-align-items-flex-start is-flex-direction-column">
            <p className="model-title mb-5
                    is-size-4-touch 
                    is-size-1-tablet 
                    is-size-2-desktop 

                    is-size-2-widescreen 
                    is-size-4-fullhd">{t("landingPage.section5.widget1.title")}</p>
            <p className="desc-text mb-5 desc-light
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 
                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-6-fullhd">
              <Trans i18nKey="landingPage.section5.widget1.desc.p1" /></p>
          </div>

          <div className="image-wrapper">
            <figure className="image">
              <img className="asset" src={model_widget1} alt="Model 1" />
            </figure>
          </div>

        </div>
      </section>

      <section className="section5 feature2 p-6 hero is-white divider">
        <div className="image-desc-container is-flex is-justify-content-space-between is-align-items-center p-6">
          <div className="image-wrapper">
            <figure className="image">
              <img className="asset" src={model_widget2} alt="Model 2" />
            </figure>
          </div>

          <div className="is-flex is-align-items-flex-start is-flex-direction-column">
            <p className="model-title mb-5
                    is-size-4-touch 
                    is-size-1-tablet 
                    is-size-2-desktop 

                    is-size-2-widescreen 
                    is-size-4-fullhd">{t("landingPage.section5.widget2.title")}</p>
            <p className="desc-text mb-5 desc-light
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 
                    is-size-6-desktop

                    is-size-6-widescreen 
                    is-size-6-fullhd desc-text">
              <Trans i18nKey="landingPage.section5.widget2.desc.p1" /><br /><br />
              <Trans i18nKey="landingPage.section5.widget2.desc.p2" />
            </p>
          </div>
        </div>

      </section>

      <section className="section5 feature3 p-6 hero is-white divider">
        <div className="image-desc-container is-flex is-justify-content-space-between is-align-items-center p-6">
          <div className="is-flex is-align-items-flex-start is-flex-direction-column">
            <p className="model-title mb-5
                    is-size-4-touch 
                    is-size-1-tablet 
                    is-size-2-desktop 

                    is-size-2-widescreen 
                    is-size-4-fullhd">{t("landingPage.section5.widget3.title")}</p>
            <p className="desc-text mb-5 desc-light
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 
                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-6-fullhd desc-text">
              <Trans i18nKey="landingPage.section5.widget3.desc.p1" /><br /><br />
              <Trans i18nKey="landingPage.section5.widget3.desc.p2" />
            </p>
          </div>
          <div className="image-wrapper">
            <figure className="image">
              <img className="asset" src={model_widget3} alt="Model 3" />
            </figure>
          </div>
        </div>
      </section>
    </section>
  )
}

export default Features;