import { useState } from "react";
import axa_logo_header from "../../assets/logos/axa_logo_header.png";
import kayrros_logo_header from "../../assets/logos/kayrros_logo_header.png";
import "./index.css";
import SideBar from "../SideBar";
import { useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion as m } from "framer-motion";

const Header = ({ style, showLogo }: any) => {
  const { t } = useTranslation();
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const location = useLocation();
  const renderLogos = () => {
    if (location.pathname === "/")
      return (
        <>
          <a
            href="https://smartservices.axaxl.com/fr/"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="image is-48x48 axa_logo mx-4">
              <img className="" src={axa_logo_header} alt="Axa Logo" />
            </figure>
          </a>
          <a href="https://www.kayrros.com/" target="_blank" rel="noreferrer">
            <figure className="image kayrros_logo mx-4">
              <img className="" src={kayrros_logo_header} alt="Kayrros logo" />
            </figure>
          </a>
        </>
      );
    else
      return (
        <>
          <a
            href="https://smartservices.axaxl.com/fr/"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="image is-48x48 axa_logo mx-4">
              <img className="" src={axa_logo_header} alt="Axa Logo" />
            </figure>
          </a>
          <a href="https://www.kayrros.com/" target="_blank" rel="noreferrer">
            <figure className="image kayrros_logo mx-4">
              <img className="" src={kayrros_logo_header} alt="Kayrros logo" />
            </figure>
          </a>
        </>
      );
  };

  const onClickHandler = () => {
    window.open("/search-details", "_blank")
    // const el = document.getElementById("search-section");
    // el && el.scrollIntoView();
  };

  return (
    <m.div className="header-container"
    animate={{ width: !showSideBar ? "0px" : "262px" }}
    transition={{ duration: 0.4 }}>
      <div
        style={{ ...style }}
        className="custom-header"
        role="navigation"
        aria-label="main navigation"
      >
        {/* <span
          className="icon is-medium is-clickable"
          style={{ paddingLeft: "50px" }}
          onClick={() => setShowSideBar(!showSideBar)}
        >
          <i
            className={`ri-menu-line ri-2x ${
              location.pathname === "/"
                ? "hamburger-icon-home"
                : "hamburger-icon"
            }`}
          ></i>
        </span> */}
        {showLogo && (
          <>
            <div className="is-flex is-flex-direction-row py-3 logos">
              {renderLogos()}
            </div>
          </>
        )}
        {location.pathname === "/" ? (
          <button
            onClick={onClickHandler}
            className="button is-size-8-fullhd has-text-white search-button mr-5"
          >
            <span>{t("header.button.title")}</span>
            {/* <span className="icon is-size-9 is-pulled-right">
              <i className="ri-arrow-right-s-line ri-lg"></i>
            </span> */}
          </button>
        ) : (
          <div></div>
        )}
      </div>
      {!showSideBar && <div className="drawer-container m-4">
        <span
          className="icon is-medium is-clickable"
          onClick={() => setShowSideBar(!showSideBar)}
        >
          <i
            className={`ri-menu-line ri-2x ${
              location.pathname === "/"
                ? "hamburger-icon-home"
                : "hamburger-icon"
            }`}
          ></i>
        </span>
      </div>}
      {showSideBar && <SideBar setShowSideBar={setShowSideBar} showSideBar={showSideBar}/>}
    </m.div>
  );
};

export default withRouter(Header);
