import * as React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import image1 from "../../assets/carousel/Carousel_1.jpg";
import image2 from "../../assets/carousel/Carousel_2.jpg";
import image3 from "../../assets/carousel/Carousel_3.jpg";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const images = [
  {
    imgPath: image1
  },
  {
    imgPath: image3,
  },
  {
    imgPath: image3,
  },
];

const Carousel = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  
  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1, borderRadius: '12px', overflow: 'hidden' }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => {
          const image = index === 0 ? image1 : (index === 1 ? image2 : image3)  
          return (<div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  backgroundImage: `url(${image})`,
                  height: 335,
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: '12px'
                }}
                // src={`image${index}`}
                // alt={step.label}
              />
            ) : null}
          </div>)
        })}
      </AutoPlaySwipeableViews>
      <div className="slider is-flex is-flex-direction-column is-align-items-center p-4">
        <p className="has-text-centered overlay-text-title mt-4">{t(`dashboard.carousel.title`)}</p>
        <p className="has-text-centered overlay-text-desc mt-5">{t(`dashboard.carousel.desc`)}</p>
        <a
          className="banner-learnmore-button is-align-self-center is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
          href="https://smartservices.axaxl.com/fr/risk-guide"
          target="_blank"
          rel="noreferrer"
        >
          <span>{t("dashboard.banner.buttonText")}</span>
          {/* <span className="icon is-small" style={{ paddingTop: "2px" }}>
            <i className="ri-arrow-drop-right-line ri-lg"></i>
          </span> */}
        </a>
        <MobileStepper sx={{ bgcolor: 'transparent' }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={null}
          backButton={null}
        />

      </div>
    </Box>
  );
};

export default Carousel;
