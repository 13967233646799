import "./index.css";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import marker from "../../assets/live-marker.png";
import { cardType } from "../../constants";

const MONTHY_INTERVAL = ['Dec', 'Jan', 'Feb', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sept', 'Oct', 'Nov', 'Dec']
const WEEKLY_INTERVAL = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']

const LineChart = ({ dataObj }: any) => {
  let data2024 = [], data2023 = []
  const data = [...dataObj.value]
  if (data[1].length > 0) {
    data2024 = data[1][data[1].length - 1]
    data2023 = data[0][data[0].length - 1]
    if (data2024.constructor === Array) {
      data[1][data[1].length - 1] = {
        x: data2024[0],
        y: data2024[1],
        marker: {
          symbol: `url(${marker})`,
          height: 40,
          width: 40,
          zIndex: 100
        },
        dataLabels: {
          enabled: true,
          format: '2024',
          align: 'center',
          verticalAlign: 'bottom',
          style: {
            color: '#BB4E44',
            fontSize: '12px',
            textOutline: 'none'
          },
          padding: 20
        }
      }
    }
    if (data2023.constructor === Array) {
      data[0][data[0].length - 1] = {
        x: data2023[0],
        y: data2023[1],
        dataLabels: {
          enabled: true,
          format: '2023',
          align: 'center',
          verticalAlign: 'bottom',
          style: {
            color: '#FFDEDB',
            fontSize: '12px',
            textOutline: 'none'
          },
          padding: 20
        }
      }
    }
  }
  
  const renderMeter = () => {
    const chartConfig = {
      title: {
        text: ''
      },
      chart: { height: 200 },
      legend:{ enabled:false },

      yAxis: {
          title: {
              text: ''
          },
          labels: {
            style: {
              color: '#BDCBDE'
            }
          },
          gridLineColor: 'transparent',
          startOnTick: false,
          min: -0.5,
      },
      tooltip: {
        valueDecimals: 0
    },
      xAxis: {
        categories: dataObj.type === cardType.type7 ? WEEKLY_INTERVAL : MONTHY_INTERVAL,
        enabled: true,
        labels: {
          style: {
            color: '#BDCBDE'
          }
        },
        minorGridLineWidth: 0,
        lineColor: 'transparent',
      },
      credits: {
        enabled: false
      },

      series: [
        {
          name: "2023",
          color: '#FFDEDB',
          data: data[0],
          lineWidth: 3,
          marker: {enabled: false}
        },
        {
          name: "2024",
          color: '#BB4E44',
          data: data[1],
          lineWidth: 3,
          marker: {
            symbol: 'circle',
            fillColor: '#FFFFFF',
            radius: 2.5,
            lineWidth: 3,
            lineColor: null // inherit from series
          }
        }
      ],
    };
    return <HighchartsReact highcharts={Highcharts} options={chartConfig} />
      
  }

  return <>{renderMeter()}</>;
};
export default LineChart;
