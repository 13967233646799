import React from "react";
import "./index.css";

const FeatureIntro = () => {
  const url = (process.env.REACT_APP_INTRO_VIDEO || "https://wildfire2023.dev.kayrros.org/videos/my_video.mp4")

  return (
    <video controls id="video-container">
      <source
        src={url}
        type="video/mp4"
      />
    </video>
  );
};

export default FeatureIntro;
