import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import kayrros_logo_header from "../../assets/logos/kayrros_logo_header.png";
import axa_logo_header from "../../assets/logos/axa_logo_header.png";

import "./index.css";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <section className="section p-6 hero has-background-light">
      <div className="columns">
        <div className="column is-5 ml-6">
          <figure className="image is-48x48 axa_logo mb-5">
            <a
              href="https://smartservices.axaxl.com/fr/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="" src={axa_logo_header} alt="Axa Logo" />
            </a>
          </figure>
          <figure className="image kayrros_logo my-3">
            <a href="https://www.kayrros.com/" target="_blank" rel="noreferrer">
              <img className="" src={kayrros_logo_header} alt="Kayrros logo" />
            </a>
          </figure>
          <div className="address my-5">{t("footer.address")}</div>
        </div>
        <div className="column is-2">
          <p
            className="footer-title mb-3
                        is-size-6-touch 
                        is-size-4-tablet 
                        is-size-5-desktop 

                        is-size-5-widescreen 
                        is-size-5-fullhd"
          >
            {t("footer.menu.title")}
          </p>
          <div className="is-flex is-flex-direction-column">
            <Link to="/" className="my-2 footer-item">
              {t("footer.menu.link1")}
            </Link>
            <Link to="/search-details" className="my-2 footer-item">
              {t("footer.menu.link2")}
            </Link>
            <a
              href="https://smartservices.axaxl.com/fr/"
              target="_blank"
              className="my-2 footer-item"
              rel="noreferrer"
            >
              {t("footer.menu.link3")}
            </a>
          </div>
        </div>
        <div className="column is-2">
          <p
            className="footer-title  mb-3
                        is-size-6-touch 
                        is-size-4-tablet 
                        is-size-5-desktop 

                        is-size-5-widescreen 
                        is-size-5-fullhd"
          >
            {t("footer.support.title")}
          </p>
          <div className="is-flex is-flex-direction-column">
            <Link to="/intro" target="_blank" rel="noreferrer" className="my-2 footer-item">
              {t("footer.support.link1")}
            </Link>
            <a href="mailto:SmartServices-EU@axaxl.com?subject=Signaler%20un%20bug"  className="my-2 footer-item">
              {t("footer.support.link3")}
            </a>
          </div>
        </div>
        <div className="column is-3">
          <p
            className="footer-title  mb-3
                        is-size-6-touch 
                        is-size-4-tablet 
                        is-size-5-desktop 

                        is-size-5-widescreen 
                        is-size-5-fullhd"
          >
            {t("footer.contacts.title")}
          </p>
          <div className="is-flex is-flex-direction-column">
            <a href="mailto:SmartServices-EU@axaxl.com?subject=Feux%20de%20Forêt 2023 - Contact"  className="my-2 footer-item">
              {t("footer.contacts.link1")}
            </a>
          </div>
        </div>
      </div>
      {/* <div className="divider m-4"/> */}
      <div className="footer-bottom is-flex is-justify-content-space-between is-align-items-flex-start is-align-self-stretch">
        <div className="footer-text">
          {t("footer.copyRight")}
        </div>
        <div className="footer-bottom-right is-flex">
          <div className="footer-text mr-4">
            <div>
              {t("footer.rights")}
            </div>
          </div>
          <div className="footer-text footer-text-decoration-underline mr-4">
            <Link to="/legal" className="">
              {t("footer.legal")}
            </Link>
            {/* <span className="is-hidden-touch">|</span> */}
          </div>
          <div className="footer-text footer-text-decoration-underline mr-4">
            <Link to="/gdpr" className="">
              {t("footer.gdpr")}
            </Link>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Footer;
