import { useTranslation, Trans } from "react-i18next";
import Counter from "./counter";

const Records = () => {
  const { t } = useTranslation();


  return (
    <section className="section2 mb-6" id="record-section">
      <div className="m-6 has-text-centered">
        <p className="title header-color
               is-size-4-mobile 
               is-size-4-touch 
               is-size-1-tablet 

               is-size-3-desktop 

               is-size-3-widescreen 
               is-size-3-fullhd">
          {t("landingPage.section2.title1")}
          <br />
          {t("landingPage.section2.title2")}
        </p>
        <br />
        <p style={{ marginTop: "-1.25rem" }} className="desc-text
            is-size-5-mobile 
            is-size-5-touch 
            is-size-2-tablet 

            is-size-5-desktop 

            is-size-5-widescreen 
            is-size-7-fullhd desc-light">
          {t("landingPage.section2.subTitle2")}
        </p>
      </div>

      <div className="columns">

        <div className="count-widget column has-text-centered p-5 ">
          <div className="columns">
            <div className="column">
              <div className="p-5 mt-6">
                <p className="count
        is-size-4-mobile 
        is-size-2-touch 
        is-size-1-tablet 

        is-size-4-desktop 

        is-size-2-widescreen 
        is-size-1-fullhd"><Counter value={{
                    prefix: t("landingPage.section2.widget1.count.prefix"),
                    value: t("landingPage.section2.widget1.count.value"),
                    unit: t("landingPage.section2.widget1.count.unit")
                  }} threshold={800}></Counter></p>


                <p className="desc-text font-16
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 

                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-6-fullhd desc-light">
                  <Trans i18nKey="landingPage.section2.widget1.desc" />
                </p>
              </div>
            </div>
          </div>

        </div>


        <div className="count-widget column has-text-centered p-5">
          <div className="p-5 mt-6">

            <p className="count
        is-size-4-mobile 
        is-size-2-touch 
        is-size-1-tablet 

        is-size-4-desktop 

        is-size-2-widescreen 
        is-size-1-fullhd"><Counter value={{
                prefix: t("landingPage.section2.widget2.count.prefix"),
                value: t("landingPage.section2.widget2.count.value"),
                unit: t("landingPage.section2.widget2.count.unit")
              }} threshold={800}></Counter></p>
            <p className="desc-text font-16
                is-size-5-mobile 
                is-size-5-touch 
                is-size-2-tablet 

                is-size-6-desktop 

                is-size-6-widescreen 
                is-size-6-fullhd desc-light">
              <Trans i18nKey="landingPage.section2.widget2.desc" />
            </p>
          </div>
        </div>


        <div className="count-widget column has-text-centered p-5">
          <div className="p-5 mt-6">

            <p className="count
        is-size-4-mobile 
        is-size-2-touch 
        is-size-1-tablet 

        is-size-4-desktop 

        is-size-2-widescreen 
        is-size-1-fullhd"><Counter value={{
                prefix: t("landingPage.section2.widget3.count.prefix"),
                value: t("landingPage.section2.widget3.count.value"),
                unit: t("landingPage.section2.widget3.count.unit")
              }} threshold={800}></Counter></p>
            <p className="desc-text font-16
                is-size-5-mobile 
                is-size-5-touch 
                is-size-2-tablet 

                is-size-6-desktop 

                is-size-6-widescreen 
                is-size-6-fullhd desc-light">
              {t("landingPage.section2.widget3.desc")}
            </p>
          </div>
        </div>

      </div>

      <div className="columns p-6 ">
        <div className="column is-2"></div>
        <div className="column is-8 m-1">
          <p className="has-text-justified
              is-size-5-mobile 
              is-size-5-touch 
              is-size-2-tablet 
              is-size-6-desktop 
              is-size-6-widescreen 
              is-size-5-fullhd desc-text desc-light font-20">
            <Trans i18nKey="landingPage.section2.desc" />
          </p>

        </div>
        <div className="column is-2"></div>
      </div>
    </section>
  )
}

export default Records;