import { useTranslation, Trans } from "react-i18next";
import model_widget1 from "../../../assets/vegetation-image.jpg";
import model_widget2 from "../../../assets/detection-image.jpg";
import model_widget3 from "../../../assets/alert-imagev2.jpg";

const Features = () => {
  const { t } = useTranslation();

  const onClickHandler = () => {
    // window.open("/search-details", "_blank")
      window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <section className="divider mt-6">

      <section className="section5 feature1 pl-6 pr-6 pt-0 hero is-white ">
        <div className="image-desc-container is-flex is-justify-content-space-between is-align-items-center p-6">
          <div className="is-flex is-align-items-flex-start is-flex-direction-column">
            <p className="model-title mb-5
                    is-size-4-touch 
                    is-size-1-tablet 
                    is-size-2-desktop 

                    is-size-2-widescreen 
                    is-size-4-fullhd">{t("landingPage.features.feature1.title")}</p>
            <p className="desc-text mb-5 desc-light
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 
                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-6-fullhd">
              <Trans i18nKey="landingPage.features.feature1.desc1" /><br /><br />
              <Trans i18nKey="landingPage.features.feature1.desc2" />
            </p>
            <button
              // disabled={!isSearchButtonActive()}
              style={{
                height: "48px",
                backgroundColor: "#000089",
                color: "#fff",
                border: "none"
              }}
              className={`search-btn button py-0
            is-size-6-touch 
            is-size-4-tablet 
            is-size-5-desktop 

            is-size-5-widescreen 
            is-size-8-fullhd`}
            onClick={onClickHandler}
            >
              {t("landingPage.features.feature1.buttonTitle")}
              {/* <span className="icon is-size-8 is-size-9-mobile pl-5 is-pulled-right pt-1">
              <i className="ri-arrow-right-s-line ri-lg"></i>
            </span> */}
            </button>
          </div>

          <div className="image-wrapper">
            <figure className="image">
              <img className="asset" src={model_widget1} alt="Model 1" />
            </figure>
          </div>

        </div>
      </section>

      <section className="section5 feature1 pl-6 pr-6 pt-0 hero is-white ">
        <div className="image-desc-container is-flex is-justify-content-space-between is-align-items-center p-6">
          <div className="image-wrapper">
            <figure className="image">
              <img className="asset" src={model_widget2} alt="Model 1" />
            </figure>
          </div>
          <div className="is-flex is-align-items-flex-start is-flex-direction-column">
            <p className="model-title mb-5
                    is-size-4-touch 
                    is-size-1-tablet 
                    is-size-2-desktop 

                    is-size-2-widescreen 
                    is-size-4-fullhd">{t("landingPage.features.feature2.title")}</p>
            <p className="desc-text mb-5 desc-light
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 
                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-6-fullhd">
              <Trans i18nKey="landingPage.features.feature2.desc" /><br /><br />
            </p>
            <button
              // disabled={!isSearchButtonActive()}
              style={{
                height: "48px",
                backgroundColor: "#000089",
                color: "#fff",
                border: "none"
              }}
              className={`search-btn button py-0
            is-size-6-touch 
            is-size-4-tablet 
            is-size-5-desktop 

            is-size-5-widescreen 
            is-size-8-fullhd`}
            onClick={onClickHandler}
            >
              {t("landingPage.features.feature2.buttonTitle")}
              {/* <span className="icon is-size-8 is-size-9-mobile pl-5 is-pulled-right pt-1">
              <i className="ri-arrow-right-s-line ri-lg"></i>
            </span> */}
            </button>
          </div>
        </div>
      </section>

      <section className="section5 feature1 pl-6 pr-6 pt-0 hero is-white ">
        <div className="image-desc-container is-flex is-justify-content-space-between is-align-items-center p-6">
          <div className="is-flex is-align-items-flex-start is-flex-direction-column">
              {/* <div className="title-tag-container mb-4">
                <p className="title-tag
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 
                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-8-fullhd">{t("landingPage.features.feature3.titleTag")}</p>

              </div> */}
              <p className="model-title mb-5
                    is-size-4-touch 
                    is-size-1-tablet 
                    is-size-2-desktop 

                    is-size-2-widescreen 
                    is-size-4-fullhd">{t("landingPage.features.feature3.title")}</p>
              <p className="desc-text mb-5 desc-light
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 
                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-6-fullhd">
                <Trans i18nKey="landingPage.features.feature3.desc" />
              </p>
              <button
                // disabled={!isSearchButtonActive()}
                style={{
                  height: "48px",
                  backgroundColor: "#000089",
                  color: "#fff",
                  border: "none"
                }}
                className={`search-btn button py-0
            is-size-6-touch 
            is-size-4-tablet 
            is-size-5-desktop 

            is-size-5-widescreen 
            is-size-8-fullhd`}
            onClick={onClickHandler}
              >
                {t("landingPage.features.feature3.buttonTitle")}
                {/* <span className="icon is-size-8 is-size-9-mobile pl-5 is-pulled-right pt-1">
              <i className="ri-arrow-right-s-line ri-lg"></i>
            </span> */}
              </button>
          </div>

          <div className="image-wrapper">
            <figure className="image">
              <img className="asset" src={model_widget3} alt="Model 1" />
            </figure>
          </div>

        </div>
      </section>
    </section>
  )
}

export default Features;