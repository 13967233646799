import { useState } from "react";
import { apiStatusConstants, cardType } from "../../constants";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion as m } from "framer-motion";
// import ProgressBar from "../ProgressBar";
import "./index.css";
import MeterGauge from '../MeterGauge'
import LineChart from '../LineChart'

const MetricCard = ({ liveModeEnabled, data, apiStatus }: any) => {
  const [showLearnMoreContent, setShowLearnMoreContent] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const learnMoreAnimation: any = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        type: "tween",
        ease: "anticipate",
        duration: 0.4,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        type: "tween",
        ease: "anticipate",
        duration: 0.4,
      },
    },
  };

  const cardContentLoadingView = () => {
    return (
      <div className="risk-box is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <TailSpin
          height="40"
          width="40"
          color="#006CD7"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  const cardContentFailureView = () => {
    return (
      <div className="risk-box is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <h1 className="is-size-7">
          Something went wrong while fetching Risk Score
        </h1>
      </div>
    );
  };
  const getValueTextForType3 = (riskValue: number) => {
    if (riskValue < 1)
      return `<1 km`
    else if (riskValue < 10)
      return `1 à 10 km`
    else
      return `>10 km`
  }

  const getChartForType3 = (riskValue: number) => {
    const defaultColor = "#F3F5F8"
    let l1Color = {
      from: defaultColor,
      to: defaultColor
    }, l2Color = {
      from: defaultColor,
      to: defaultColor
    }, l3Color = {
      from: defaultColor,
      to: defaultColor
    }
    if (riskValue < 1) {
      l1Color = {
        from: "#FFEEB2",
        to: "#BFE7B4"
      }
      l2Color = {
        from: "#FFBA9C",
        to: "#FFEEB2"
      }
      l3Color = {
        from: "#FF8787",
        to: "#FFBA9C"
      }
    } else if (riskValue < 10) {
      l1Color = {
        from: "#FFEEB2",
        to: "#BFE7B4"
      }
      l2Color = {
        from: "#FFBA9C",
        to: "#FFEEB2"
      }
    } else {
      l1Color = {
        from: "#FFEEB2",
        to: "#BFE7B4"
      }
    }

    return (
      <div className="meter-container">
        <div className="circle1">
          <div className="circle1-outer" style={{ backgroundImage: `linear-gradient(to top, ${l1Color.from}, ${l1Color.to})` }}></div>
          <div className="circle1-inner"></div>
        </div>
        <div className="circle2">
          <div className="circle2-outer" style={{ backgroundImage: `linear-gradient(to top, ${l2Color.from}, ${l2Color.to})` }}></div>
          <div className="circle2-inner"></div>
        </div>
        <div className="circle3">
          <div className="circle3-outer" style={{ backgroundImage: `linear-gradient(to top, ${l3Color.from}, ${l3Color.to})` }}></div>
          <div className="circle3-inner"></div>
        </div>
      </div>
    );
  };

  const getCard5Desc = (value: number) => {
    return t(`dashboard.fire.card5.descVegetation${(()=>{
      if (value < 30) return 1
      if (value < 50) return 2
      if (value < 60) return 3
      if (value < 80) return 4
      else return 5
    })()}`)
  }


  const numberWithSpaces = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const getCardData = () => {
    let dataFor2024, valueText
    switch (data.type) {
      case cardType.type1:
        return {
          icon: 'ri-fire-line',
          title: t("dashboard.fire.card1.title"),
          desc: t(`dashboard.fire.card1.desc${data.value}`),
          showMoreDesc: t("dashboard.fire.card1.showMoreDesc"),
          showMoreTitle: t("dashboard.fire.card1.title"),
          valueText: `${data.value}/5`,
          chart: <MeterGauge data={{
            value: data.value,
            min: 0,
            max: 5
          }}></MeterGauge>
        }
      case cardType.type2:
        return {
          icon: 'ri-donut-chart-fill',
          valueText: `${Math.round(data.value)}%`,
          title: t("dashboard.fire.card2.title"),
          showMoreDesc: t("dashboard.fire.card2.showMoreDesc"),
          showMoreTitle: t("dashboard.fire.card2.title"),
          desc: data.value < 50 ? t("dashboard.fire.card2.desc1").replace("VALUE1", `${data.value}%`)
            : (data.value < 100 ? t("dashboard.fire.card2.desc2").replace("VALUE2", `${(100 - data.value).toString()}%`)
              : t("dashboard.fire.card2.desc3")),
          chart: <MeterGauge data={{
            value: data.value,
            min: 0,
            max: 100
          }}></MeterGauge>
        }
      case cardType.type3:
        return {
          icon: 'ri-compass-discover-line',
          title: t("dashboard.fire.card3.title"),
          desc: t(`dashboard.fire.card3.desc${data.value > 10 ? 1 : (data.value < 1 ? 3 : 2)}`),
          showMoreDesc: t("dashboard.fire.card3.showMoreDesc"),
          showMoreTitle: t("dashboard.fire.card3.title"),
          valueText: getValueTextForType3(data.value),
          chart: getChartForType3(data.value)
        }
      case cardType.type4:
        return {
          icon: 'ri-fire-line',
          title: t("dashboard.fire.card4.title"),
          desc: t(`dashboard.fire.card4.descVegetation${data.value}`),
          valueText: t(`dashboard.fire.card4.value${data.value}`),
          showMoreTitle: t("dashboard.fire.card4.title"),
          showMoreDesc: t("dashboard.fire.card4.showMoreDesc"),
          // valueText: `${data.value}/5`,
          chart: <MeterGauge data={{
            value: data.value,
            min: 0,
            max: 5
          }}></MeterGauge>
        }
      case cardType.type5:
        return {
          icon: 'ri-donut-chart-fill',
          valueText: `${Math.round(data.value*100)}%`,
          title: t("dashboard.fire.card5.title"),
          desc: getCard5Desc(data.value * 100),
          showMoreTitle: t("dashboard.fire.card5.title"),
          showMoreDesc: t("dashboard.fire.card5.showMoreDesc"),
          chart: <MeterGauge data={{
            value: data.value*100,
            min: 0,
            max: 100
          }}></MeterGauge>
        }
      case cardType.type6:
        return {
          icon: 'ri-compass-discover-line',
          title: t("dashboard.fire.card6.title"),
          desc: t(`dashboard.fire.card6.desc${data.value > 5 ? 1 : (data.value < 0.5 ? 3 : 2)}`),
          valueText: `${data.value} km`,
          chart: getChartForType3(data.value),
          showMoreTitle: t("dashboard.fire.card6.title"),
          showMoreDesc: t("dashboard.fire.card6.showMoreDesc"),
        }
      case cardType.type7:
        dataFor2024 = data.value[1]
        valueText = '0 ha'
        if (dataFor2024.length > 0) {
          if (dataFor2024[dataFor2024.length - 1].constructor === Array) {
            valueText = `${Math.round(dataFor2024[dataFor2024.length - 1][1])} ha`
          }
          else {
            valueText = `${Math.round(dataFor2024[dataFor2024.length - 1].y)} ha`
          }
        }
        return {
          icon: 'ri-donut-chart-fill',
          valueText,
          title: t("dashboard.fire.card7.title"),
          chart: <LineChart dataObj={data}></LineChart>,
          showMoreTitle: t("dashboard.fire.card7.title"),
          showMoreDesc: t("dashboard.fire.card7.showMoreDesc"),
        }
      case cardType.type8:
        dataFor2024 = data.value[1]
        valueText = '0'
        if (dataFor2024.length > 0) {
          if (dataFor2024[dataFor2024.length - 1].constructor === Array) {
            valueText = numberWithSpaces(Math.round(dataFor2024[dataFor2024.length - 1][1]))
          }
          else {
            valueText = numberWithSpaces(Math.round(dataFor2024[dataFor2024.length - 1].y))
          }
        }
        return {
          icon: 'ri-fire-line',
          valueText,
          title: t("dashboard.fire.card8.title"),
          chart: <LineChart dataObj={data}></LineChart>,
          showMoreTitle: t("dashboard.fire.card8.title"),
          showMoreDesc: t("dashboard.fire.card8.showMoreDesc"),
        }
      case cardType.type9:
        dataFor2024 = data.value[1]
        valueText = '0 ha'
        if (dataFor2024.length > 0) {
          if (dataFor2024[dataFor2024.length - 1].constructor === Array) {
            valueText = `${numberWithSpaces(Math.round(dataFor2024[dataFor2024.length - 1][1]))} ha`
          }
          else {
            valueText = `${numberWithSpaces(Math.round(dataFor2024[dataFor2024.length - 1].y))} ha`
          }
        }
        return {
          icon: 'ri-compass-discover-line',
          valueText,
          title: t("dashboard.fire.card9.title"),
          chart: <LineChart dataObj={data}></LineChart>,
          showMoreTitle: t("dashboard.fire.card9.title"),
          showMoreDesc: t("dashboard.fire.card9.showMoreDesc"),
        }
    }
    return {
      valueText: '',
      title: '',
      desc: '',
      chartData: {}
    }
  }

  const cardContentSuccessView = () => {
    const cardData = getCardData()
    const date = new Date() 
    return (
      <>
        <div className="risk-box is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-stretch" onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setShowLearnMoreContent(true);
              }}>
          <span className="icon icons is-flex has-text-black mb-3 is-flex-wrap-wrap is-justify-content-space-between">
            <i className={`${cardData.icon ?? 'ri-fire-line'} ri-lg`}></i>
            {/* <i className="ri-arrow-drop-right-line ri-lg"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setShowLearnMoreContent(true);
              }}></i> */}
          </span>
          <div className="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-stretch">
            <div className="has-text-centered marign-class mb-4">
              <p className="value-text mb-4">{cardData.valueText}</p>
              <p className="uppercase-text value-subtext mb-3">{cardData.title}</p>
              {liveModeEnabled && <p className="value-date">{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</p>} 
            </div>
            {!liveModeEnabled && <div className="chart-container">
              {cardData.chart}
            </div>}
            {liveModeEnabled && <div className="chart-container-live">
              {cardData.chart}
            </div>}
            <p style={{width:'80%', margin: '0px auto'}} className="card-desc mt-3">{cardData.desc ?? ''}</p>
          </div>
        </div>
        <AnimatePresence>
          {showLearnMoreContent && (
            <m.div
              variants={learnMoreAnimation}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="risk-box-learnmore is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-stretch"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setShowLearnMoreContent(false);
              }}
            >
              <span className="icon has-text-white">
              <i className={`${cardData.icon ?? 'ri-fire-line'} ri-lg`}></i>
              </span>
              <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center px-2">
                <h1 className="uppercase-text show-more-title mb-4 has-text-centered">
                {cardData.showMoreTitle}
                </h1>
                <p
                  className="show-more-desc has-text-justified title-text-color"
                >
                  {cardData.showMoreDesc}
                </p>
              </div>
            </m.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  const renderCardContent = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return cardContentSuccessView();
      case apiStatusConstants.inProgress:
        return cardContentLoadingView();
      case apiStatusConstants.failure:
        return cardContentFailureView();
      default:
        return cardContentLoadingView();
    }
  };

  return <>{renderCardContent()}</>;
};
export default MetricCard;
