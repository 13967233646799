import { Slider } from "@mui/material";
import { AnimatePresence, motion as m } from "framer-motion";
import React, { useState } from "react";
import MapComponent from "../MapComponent";
import opacity_logo from "../../assets/opacity.svg";
import mapInfoIcon from "../../assets/map-info.svg";
import arrowIcon from "../../assets/arrow.svg";
import mapStyle1 from "../../assets/map-style1.png";
import mapStyle2 from "../../assets/map-style2.png";
import "./index.css";
import { useTranslation } from "react-i18next";


const MapContainer = ({ togglePopup, liveModeEnabled, data, suggestionData, isForestFireMode }: any) => {
  const { t } = useTranslation();
  const MAP_STYLES = [
    'mapbox://styles/mapbox/satellite-v9',
    'mapbox://styles/mapbox/streets-v11'
  ]
  const WILDFIRE_INFO = {
    title: t(`dashboard.map.wildfire.infoTitle`),
    data: [
      {
        color: '#BFE7B4',
        text: t(`dashboard.map.wildfire.infoDesc1`)
      },
      {
        color: '#FFEEB2',
        text: t(`dashboard.map.wildfire.infoDesc2`)
      },
      {
        color: '#FFBA9C',
        text: t(`dashboard.map.wildfire.infoDesc3`)
      },
      {
        color: '#FF8787',
        text: t(`dashboard.map.wildfire.infoDesc4`)
      },
      {
        color: '#AF4338',
        text: t(`dashboard.map.wildfire.infoDesc5`)
      },
    ]
  }
  const VEGETATION_INFO = {
    title: t(`dashboard.map.vegetation.infoTitle`),
    data: [
      {
        color: [191, 231, 180],
        text: t(`dashboard.map.vegetation.infoDesc1`)
      },
      {
        color: [255, 238, 178],
        text: t(`dashboard.map.vegetation.infoDesc2`)
      },
      {
        color: [255, 186, 156],
        text: t(`dashboard.map.vegetation.infoDesc3`)
      },
      {
        color: [255, 135, 135],
        text: t(`dashboard.map.vegetation.infoDesc4`)
      },
      {
        color: [175, 67, 56],
        text: t(`dashboard.map.vegetation.infoDesc5`)
      },
    ]
  }
  const LIVE_INFO = {
    title: t(`dashboard.map.live.infoTitle`),
    data: [
      {
        color: '#FFDEDB',
        text: t(`dashboard.map.live.infoDesc1`)
      },
      {
        color: '#BB4E44',
        text: t(`dashboard.map.live.infoDesc2`)
      }
    ]
  }
  const infoTitle = isForestFireMode ? (liveModeEnabled ? LIVE_INFO.title : WILDFIRE_INFO.title) : VEGETATION_INFO.title
  const infoData = isForestFireMode ? (liveModeEnabled ? LIVE_INFO.data : WILDFIRE_INFO.data) : VEGETATION_INFO.data

  const [mapOpactiyState, setMapOpactiyState] = useState<number>(100);

  const [showSlider, setShowSlider] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [mapStyle, setMapStyle] = useState<string>(MAP_STYLES[0]);

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  // useEffect(() => {
  //   setMapOpactiyState(liveModeEnabled ? 100 : (isForestFireMode ? 100 : 0))
  // }, [isForestFireMode, liveModeEnabled])

  const valuetext = (value: number) => {
    return `${value}%`;
  };

  const getRGBValue = (rgb: any) => {
    if (typeof rgb === 'string')
      return rgb
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
  }

  return (
    <div className="map-container">
      <div className="map-component">

        <MapComponent
          liveModeEnabled={liveModeEnabled}
          opacity={mapOpactiyState}
          data={data}
          location={{
            lat: suggestionData.center[1],
            long: suggestionData.center[0],
            name: suggestionData.text
          }}
          mapStyle={mapStyle}
          isForestFireMode={isForestFireMode}
          colors={VEGETATION_INFO.data.map(i => i.color)}
        />
      </div>
      <div className="slider-switch-container">
        <div
          className="slider-switch is-clickable"
          style={showSlider ? { backgroundColor: "#D3D3D3" } : {}}
          onClick={() => togglePopup(!showSlider, setShowSlider)}
        >
          <img alt="slider-icon" style={{ width: "32px" }} src={opacity_logo} />
        </div>
      </div>
      <div
        onClick={() => togglePopup(!showInfo, setShowInfo)}
        className="info-icon"
      > <img src={mapInfoIcon} alt="map-marker" /></div>
      <AnimatePresence>
        {showSlider && (
          <m.div
            className="slider-container"
            // onBlur={() => setShowSlider(false)}
            initial={{ x: 12, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.3,
              type: "tween",
              ease: "anticipate",
            }}
            exit={{ x: 12, opacity: 0 }}
          >
            <div className="slider-element pl-4 pr-5 pt-3 pb-1">
              <p
                className="has-text-centered mr-4 has-text-weight-medium has-text-left has-text-black"
                style={{ fontSize: "13px" }}
              >
                Opacité: {mapOpactiyState}%
              </p>
              <Slider
                size="small"
                aria-label="Opacity of the Map"
                defaultValue={40}
                getAriaValueText={valuetext}
                step={10}
                valueLabelDisplay="auto"
                marks={marks}
                min={0}
                max={100}
                value={mapOpactiyState}
                onChange={(
                  event: React.SyntheticEvent | Event,
                  value: number | number[]
                ) => setMapOpactiyState(value as number)}
                sx={{
                  color: "#000089",
                  "& .MuiSlider-markLabel": {
                    fontSize: "12px",
                  },
                }}
              />
            </div>
          </m.div>
        )}

        {showInfo && (
          <m.div
            className="info-container"
            onBlur={() => setShowInfo(false)}
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: 12, opacity: 1 }}
            transition={{
              duration: 0.3,
              type: "tween",
              ease: "anticipate",
            }}
            exit={{ y: 0, opacity: 0 }}
          >
            <img alt="slider-icon" style={{ width: "20px" }} src={arrowIcon} />
            <div className="info-body">
              <p className="info-title">{infoTitle}</p>
              <div>
                {
                  infoData.map(item => {
                    return (
                      <div className="info-row is-flex is-align-items-center my-4"><div className="info-color" style={{ backgroundColor: getRGBValue(item.color) }}></div><div className="info-text ml-4">{item.text}</div></div>
                    )
                  })
                }
              </div>
            </div>
          </m.div>
        )}

        <div className="mapstyles-container is-flex is-align-items-center">
          <div className="mapstyles-item" onClick={() => setMapStyle(MAP_STYLES[0])}><img alt="slider-icon" src={mapStyle1} /></div>
          <div className="mapstyles-item" onClick={() => setMapStyle(MAP_STYLES[1])}><img alt="slider-icon" src={mapStyle2} /></div>
        </div>

      </AnimatePresence>
    </div>
  );
};

export default MapContainer;
