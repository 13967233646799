import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MapContainer from "../MapContainer";
import MetricCard from "../MetricCard";
import carousel from "../../assets/carousel/Carousel_1.jpg";
import reportHeader from "../../assets/reportHeader.jpg";
import fireHeader from "../../assets/report-fire-header.png";
import vegHeader from "../../assets/report-veg-header.png";
import reportFooter from "../../assets/report-footer.jpg";


import { apiStatusConstants } from "../../constants";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Report = ({ fireData, vegData, trigger, suggestionData, finishCb }: any) => {
  const { t } = useTranslation();
  const CAPTURE_DIV_ID = 'capture-container'
  const TIMEOUT_INTERVAL = 500
  const containerRef = useRef(null);

  const DOC_WIDTH = 595
  const DOC_HEIGHT = 812
  const BODY_WIDTH = 557

  const QUALITY = 0.8


  useEffect(() => {
    if (trigger) {
      setTimeout(async () => {
        let pdf = new jsPDF('p', 'px', [DOC_HEIGHT, DOC_WIDTH]);

        async function compressImage(imgElement: any, quality: number) {
          const canvas = await html2canvas(imgElement);
          return {
            dataURL: canvas.toDataURL('image/jpeg', quality),
            width: canvas.width,
            height: canvas.height
          };
        }

        pdf.addImage(reportHeader, 'JPEG', 0, 0, DOC_WIDTH, 102);
        pdf.addImage(fireHeader, 'JPEG', 18, 126, 114, 15);

        let captureItem = document.getElementById(`${CAPTURE_DIV_ID}-1`);
        if (captureItem) {
          const { dataURL, width, height } = await compressImage(captureItem, QUALITY);
          const imgHeight = (height * BODY_WIDTH) / width;
          pdf.addImage(dataURL, 'JPEG', 18, 156, BODY_WIDTH, imgHeight);
        }

        pdf.addImage(vegHeader, 'JPEG', 18, 450, 137, 15);
        captureItem = document.getElementById(`${CAPTURE_DIV_ID}-2`);
        if (captureItem) {
          const { dataURL, width, height } = await compressImage(captureItem, QUALITY);
          const imgHeight = (height * BODY_WIDTH) / width;
          pdf.addImage(dataURL, 'JPEG', 18, 480, BODY_WIDTH, imgHeight);
        }

        pdf.addImage(reportFooter, 'JPEG', 0, 770, DOC_WIDTH, 42);

        // console.log('saving pdf...');
        const fileName = suggestionData?.text?.replaceAll('.', '_') ?? 'report';
        pdf.save(fileName);


        finishCb()
      }, TIMEOUT_INTERVAL)

    }
  }, [trigger, suggestionData?.text, finishCb])

  // console.log(suggestionData)

  const renderReport = () => {
    return (
      <div id={CAPTURE_DIV_ID}>
        <div id='header'>
          <img
            className="carousel-image"
            src={reportHeader}
            alt="header"
            style={{ width: "100%" }}
          />
        </div>
        <div id={`${CAPTURE_DIV_ID}-1`} className="screen-shot-container">
          <div
            className="is-flex mt-4 is-flex-direction-row is-justify-content-flex-start is-align-items-center title-text-color"
          >
            <span className="icon">
              <i className="ri-dashboard-line ri-lg"></i>
            </span>
            <p className="search-details-title1 ml-2">{t("dashboard.header.title1")}</p>
          </div>
          <div className="is-flex is-flex-direction-row is-align-items-baseline is-justify-content-space-between">
            <div className="is-flex is-flex-direction-row is-align-items-baseline mb-4">
              <p className="search-details-title">
                {suggestionData?.place_name || "-"}
              </p>
              {/* {suggestionData?.address && <p className="search-details-subtitle">
                {`, ${suggestionData?.address || "-"}`}
              </p>} */}
            </div>
          </div>
          <div className="screen-shot-body is-flex">
            <div className="screen-shot-mapcontainer">
              {fireData.mapData && fireData.mapData.data && <MapContainer liveModeEnabled={false} isForestFireMode={true}
                suggestionData={suggestionData}
                data={fireData.mapData}
              // imageReady={imageReadyFire}
              />}
            </div>
            <div className="screen-shot-cards-container">
              <div
                className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start is-flex-wrap-wrap"
                style={{ height: "100%", width: "100%", gap: "20px 20px" }}
              >
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <MetricCard liveModeEnabled={false} data={fireData.cardData[0]} apiStatus={apiStatusConstants.success}></MetricCard>
                </div>
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <MetricCard liveModeEnabled={false} data={fireData.cardData[1]} apiStatus={apiStatusConstants.success}></MetricCard>
                </div>
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <MetricCard liveModeEnabled={false} data={fireData.cardData[2]} apiStatus={apiStatusConstants.success}></MetricCard>
                </div>
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <div className="carousel">
                    <img
                      className="carousel-image"
                      src={carousel}
                      alt="carousel"
                      style={{ height: "100%", width: "100%" }}
                    />
                    <div className="is-flex is-flex-direction-column is-align-items-center p-4" style={{ position: 'absolute', height: "100%", width: "100%" }}>
                      <p className="has-text-centered overlay-text-title mt-4">{t(`dashboard.carousel.title`)}</p>
                      <p className="has-text-centered overlay-text-desc mt-5">{t(`dashboard.carousel.desc`)}</p>
                      <p
                        className="banner-learnmore-button is-align-self-center is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                      >
                        <span>{t("dashboard.banner.buttonText")}</span>
                      </p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id={`${CAPTURE_DIV_ID}-2`} className="screen-shot-container">
          <div
            className="is-flex mt-4 is-flex-direction-row is-justify-content-flex-start is-align-items-center title-text-color"
          >
            <span className="icon">
              <i className="ri-dashboard-line ri-lg"></i>
            </span>
            <p className="search-details-title1 ml-2">{t("dashboard.header.title1")}</p>
          </div>
          <div className="is-flex is-flex-direction-row is-align-items-baseline is-justify-content-space-between">
            <div className="is-flex is-flex-direction-row is-align-items-baseline mb-4">
              <p className="search-details-title">
                {suggestionData?.place_name || "-"}
              </p>
              {/* {suggestionData?.address && <p className="search-details-subtitle">
                {`, ${suggestionData?.address || "-"}`}
              </p>} */}
            </div>
          </div>
          <div className="screen-shot-body is-flex">
            <div ref={containerRef} className="screen-shot-mapcontainer">
              {vegData.mapData && vegData.mapData.data && <MapContainer liveModeEnabled={false} isForestFireMode={false}
                suggestionData={suggestionData}
                data={vegData.mapData}
              // imageReady={imageReadyFire}
              />}
            </div>
            <div className="screen-shot-cards-container">
              <div
                className="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-flex-start is-flex-wrap-wrap"
                style={{ height: "100%", width: "100%", gap: "20px 20px" }}
              >
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <MetricCard liveModeEnabled={false} data={vegData.cardData[0]} apiStatus={apiStatusConstants.success}></MetricCard>
                </div>
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <MetricCard liveModeEnabled={false} data={vegData.cardData[1]} apiStatus={apiStatusConstants.success}></MetricCard>
                </div>
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <MetricCard liveModeEnabled={false} data={vegData.cardData[2]} apiStatus={apiStatusConstants.success}></MetricCard>
                </div>
                <div className="card-container"
                  style={{
                    width: "calc(50% - 10px)",
                  }}
                >
                  <div className="carousel">
                    <img
                      className="carousel-image"
                      src={carousel}
                      alt="carousel"
                      style={{ height: "100%", width: "100%" }}
                    />
                    <div className="is-flex is-flex-direction-column is-align-items-center p-4" style={{ position: 'absolute', height: "100%", width: "100%" }}>
                      <p className="has-text-centered overlay-text-title mt-4">{t(`dashboard.carousel.title`)}</p>
                      <p className="has-text-centered overlay-text-desc mt-5">{t(`dashboard.carousel.desc`)}</p>
                      <p
                        className="banner-learnmore-button is-align-self-center is-flex is-flex-direction-row is-justify-content-center is-align-items-center"
                      >
                        <span>{t("dashboard.banner.buttonText")}</span>
                      </p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    renderReport()
  )
}

export default Report;