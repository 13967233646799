import { useTranslation, Trans } from "react-i18next";
import axa_logo_header from "../../../assets/logos/axa_logo_header.png";
import kayrros_logo_white from "../../../assets/logos/kayrros_logo_white.png";


const Approach = () => {
  const approachLink = 'https://axaxl.com/fr/press-releases/axa-unveils-wildfire-risk-prevention-service-in-france'
  const { t } = useTranslation();
  const approachButtonClickHandler = () => {

    window.open(approachLink, "_blank") //to open new page
  }
  const renderLogos = () => {
    return (
      <>
        <a
          href="https://smartservices.axaxl.com/fr/"
          target="_blank"
          rel="noreferrer"
        >
          <figure className="image is-48x48 axa_logo mx-4">
            <img className="" src={axa_logo_header} alt="Axa Logo" />
          </figure>
        </a>
        <a href="https://www.kayrros.com/" target="_blank" rel="noreferrer">
          <figure className="image kayrros_logo mx-4">
            <img className="" src={kayrros_logo_white} alt="Kayrros logo" />
          </figure>
        </a>
      </>
    );
  };
  return (
    <section className="approach p-6 hero is-dark">
      <div className="is-flex is-flex-direction-row" style={{ justifyContent: "center" }}>
        {renderLogos()}
      </div>
      <div className="columns ">
        <div className="column is-8 approach-container">
          <p className="approach-title py-6 is-flex is-justify-content-center  
                is-size-4-touch 
                is-size-1-tablet 

                is-size-3-desktop 

                is-size-3-widescreen 
                is-size-3-fullhd">{t("landingPage.approach.title")}</p>
          <p className="subtitle has-text-centered
                is-size-5-mobile 
                is-size-5-touch 
                is-size-2-tablet 
                is-size-6-desktop 

                is-size-6-widescreen 
                is-size-5-fullhd desc-text desc-light">
            <Trans i18nKey="landingPage.approach.desc.p1" /><br /><br />
          </p>
          <button
            // disabled={!isSearchButtonActive()}
            style={{
              display: "flex",
              margin: "0 auto",
              color: "#000089"
            }}
            className={`search-btn button py-0
            is-size-6-touch 
            is-size-4-tablet 
            is-size-5-desktop 

            is-size-5-widescreen 
            is-size-7-fullhd`}
            onClick={approachButtonClickHandler}
          >
            {t("landingPage.approach.searchButtonText")}
          </button>
        </div>
      </div>
    </section>
  )
}

export default Approach;