import React, { useEffect, useState } from 'react';

const ANIMATION_TIME = 2000

const Counter = ({ value, threshold }: any) => {
  const [count, setCount] = useState(0);
  const [shouldStart, setShouldStart] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (!shouldStart && window.scrollY > threshold) {
        setShouldStart(true);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [shouldStart, threshold]);

  useEffect(() => {
    let timer: any;
    if (shouldStart) {

      let start = 0;
      const end = parseInt(value.value)
      let timer = setInterval(() => {
        start += 1;
        setCount(start)
        if (start === end) clearInterval(timer)
      }, ANIMATION_TIME / value.value);
    }

    return () => clearInterval(timer);
  }, [shouldStart, value.value]);

  return (
    <>{value.prefix}{count}{value.unit}</>
  );
};

export default Counter;
