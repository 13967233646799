import { useTranslation, Trans } from "react-i18next";
import france_map from "../../../assets/france-map-heatmapv3.gif";
import Counter from "./counter";

const Outlook = () => {
  const COUNTER_Y = 2600
  const { t } = useTranslation();
  return (
    <section className="section3 px-6 hero is-fullheight">
      {/* image container  */}
      <div className="columns p-6 is-flex is-align-items-center">

        <div className="column is-6 p-6 image-wrapper ">
          <div className="image-holder pl-5">
            {/* <p className="subtitle has-text-white m-1
                    is-size-6-touch 
                    is-size-4-tablet 
                    is-size-5-desktop 
                    is-size-5-widescreen 
                    is-size-5-fullhd">{t("landingPage.section3.widget1.image.title")}</p>
                  <p className="subtitle has-text-white 
                    is-size-6-touch 
                    is-size-4-tablet 
                    is-size-6-desktop 
                    is-size-6-widescreen 
                    is-size-6-fullhd">{t("landingPage.section3.widget1.image.subtitle")}</p> */}

            <figure className="image ">
              <img className="asset" src={france_map} alt="France Map" style={{ animationDuration: '10s' }} />
            </figure>
          </div>
        </div>
        <div className="column is-6 py-6 px-0 is-flex is-align-items-center">
          <div className="">
            <p className="outlook-title1 m-1 py-4
                    is-size-7-fullhd">{`${t("landingPage.perspectives.subTitle")}`}</p>
            <p className="outlook-title2 pb-5
                    is-size-3-fullhd">{t("landingPage.perspectives.title")}</p>
            <p className="subtitle pb-3 has-text-justified
                    is-size-5-mobile 
                    is-size-5-touch 
                    is-size-2-tablet 

                    is-size-6-desktop 

                    is-size-6-widescreen 
                    is-size-5-fullhd desc-text desc-light">
              <Trans i18nKey="landingPage.perspectives.desc" /><br /><br />
            </p>
            <div className="">

              <p className="outlook-row-title pb-4
                    is-size-8-fullhd ">{t("landingPage.perspectives.metricsRow1.desc")}</p>
              <div className="metricRow is-flex is-justify-content-center is-justify-content-space-evenly">

                <div className="metricContainer column">

                  <p
                    className="count
                      is-size-4-mobile 
                      is-size-2-touch 
                      is-size-1-tablet 

                      is-size-4-desktop 
                      has-text-weight-bold
                      is-size-2-widescreen 
                      is-size-4-fullhd"><Counter value={{
                      prefix: t("landingPage.perspectives.metricsRow1.metric1.value.prefix"),
                      value: t("landingPage.perspectives.metricsRow1.metric1.value.value"),
                      unit: t("landingPage.perspectives.metricsRow1.metric1.value.unit")
                    }} threshold={COUNTER_Y}></Counter></p>
                  <p className="outlook-metric-desc pt-3 pb-5
                            is-size-8-fullhd">{t("landingPage.perspectives.metricsRow1.metric1.desc")}</p>
                </div>
                <div className="metricContainer column">

                  <p
                    className="count
                      is-size-4-mobile 
                      is-size-2-touch 
                      is-size-1-tablet 

                      is-size-4-desktop 
                      has-text-weight-bold
                      is-size-2-widescreen 
                      is-size-4-fullhd"><Counter value={{
                      prefix: t("landingPage.perspectives.metricsRow1.metric2.value.prefix"),
                      value: t("landingPage.perspectives.metricsRow1.metric2.value.value"),
                      unit: t("landingPage.perspectives.metricsRow1.metric2.value.unit")
                    }} threshold={COUNTER_Y}></Counter></p>
                  <p className="outlook-metric-desc pt-3 pb-5
                            is-size-8-fullhd">{t("landingPage.perspectives.metricsRow1.metric2.desc")}</p>
                </div>
                <div className="metricContainer column">

                  <p
                    className="count
                      is-size-4-mobile 
                      is-size-2-touch 
                      is-size-1-tablet 

                      is-size-4-desktop 
                      has-text-weight-bold
                      is-size-2-widescreen 
                      is-size-4-fullhd"><Counter value={{
                      prefix: t("landingPage.perspectives.metricsRow1.metric3.value.prefix"),
                      value: t("landingPage.perspectives.metricsRow1.metric3.value.value"),
                      unit: t("landingPage.perspectives.metricsRow1.metric3.value.unit")
                    }} threshold={COUNTER_Y}></Counter></p>
                  <p className="outlook-metric-desc pt-3 pb-5
                            is-size-8-fullhd">{t("landingPage.perspectives.metricsRow1.metric3.desc")}</p>
                </div>
              </div>
              <p className="outlook-row-title pb-4
                    is-size-8-fullhd">{t("landingPage.perspectives.metricsRow2.desc")}</p>
              <div className="metricRow is-flex is-justify-content-center is-justify-content-space-evenly">

                <div className="metricContainer column">

                  <p
                    className="count
                      is-size-4-mobile 
                      is-size-2-touch 
                      is-size-1-tablet 

                      is-size-4-desktop 
                      has-text-weight-bold
                      is-size-2-widescreen 
                      is-size-4-fullhd"><Counter value={{
                      prefix: t("landingPage.perspectives.metricsRow2.metric1.value.prefix"),
                      value: t("landingPage.perspectives.metricsRow2.metric1.value.value"),
                      unit: t("landingPage.perspectives.metricsRow2.metric1.value.unit")
                    }} threshold={COUNTER_Y}></Counter></p>
                  <p className="outlook-metric-desc pt-3 pb-5
                            is-size-8-fullhd">{t("landingPage.perspectives.metricsRow2.metric1.desc")}</p>
                </div>
                <div className="metricContainer column">

                  <p
                    className="count
                      is-size-4-mobile 
                      is-size-2-touch 
                      is-size-1-tablet 

                      is-size-4-desktop 
                      has-text-weight-bold
                      is-size-2-widescreen 
                      is-size-4-fullhd"><Counter value={{
                      prefix: t("landingPage.perspectives.metricsRow2.metric2.value.prefix"),
                      value: t("landingPage.perspectives.metricsRow2.metric2.value.value"),
                      unit: t("landingPage.perspectives.metricsRow2.metric2.value.unit")
                    }} threshold={COUNTER_Y}></Counter></p>
                  <p className="outlook-metric-desc pt-3 pb-5
                            is-size-8-fullhd">{t("landingPage.perspectives.metricsRow2.metric2.desc")}</p>
                </div>
                <div className="metricContainer column">

                  <p
                    className="count
                      is-size-4-mobile 
                      is-size-2-touch 
                      is-size-1-tablet 

                      is-size-4-desktop 
                      has-text-weight-bold
                      is-size-2-widescreen 
                      is-size-4-fullhd"><Counter value={{
                      prefix: t("landingPage.perspectives.metricsRow2.metric3.value.prefix"),
                      value: t("landingPage.perspectives.metricsRow2.metric3.value.value"),
                      unit: t("landingPage.perspectives.metricsRow2.metric3.value.unit")
                    }} threshold={COUNTER_Y}></Counter></p>
                  <p className="outlook-metric-desc pt-3 pb-5
                            is-size-8-fullhd">{t("landingPage.perspectives.metricsRow2.metric3.desc")}</p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </section>
  )
}

export default Outlook;