import { Trans } from "react-i18next";
import SearchAutoComplete from "../../SearchAutoComplete";

const Search = () => {
    return (
        <section className="section7 search hero is-ghost divider" id="search-section">
          <div className="hero-body">
            <div className="columns has-text-centered">
              <div className="column searchSection is-three-fifths is-offset-one-fifth">
                <p className="model-title mb-5
                  is-size-4-touch has-text-white
                  is-size-1-tablet 
                  is-size-2-desktop 
                  
                  is-size-2-widescreen 
                  is-size-4-fullhd">
                    <Trans i18nKey="landingPage.search.title" />
                  </p>

                <p className="desc-text mb-5 has-text-white 
                  is-size-5-mobile 
                  is-size-5-touch 
                  is-size-2-tablet 
                  is-size-5-desktop 

                  is-size-5-widescreen 
                  is-size-7-fullhd">
                    <Trans i18nKey="landingPage.search.desc" />
                  </p>

                <SearchAutoComplete showButton={true} placeName={""}  width={"70%"}/>
              </div>
              </div>
            </div>
          </section>
    );
};

export default Search;
