import { Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import LegalPage from "./components/Legal";
import GDPRPage from "./components/GDPR";
import TokenValidator from "./components/TokenValidator";
import SearchDetails from "./components/SearchDetails";
// import MultiReportGenerator from "./components/MultiReportGenerator";
import ServerErrorPage from "./components/ServerErrorPage";
import i18next from "i18next";
import { useEffect } from "react";
import FeatureIntro from "./components/FeatureIntro";

function App() {
  useEffect(() => {
    // const lng = navigator.language;
    i18next.changeLanguage("fr");
  }, []);
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/legal" component={LegalPage} />
      <Route exact path="/gdpr" component={GDPRPage} />
      <Route exact path="/search-details" component={SearchDetails} />
      {/* <Route exact path="/multi-report-generator" component={MultiReportGenerator} /> */}
      <Route path="/error" component={ServerErrorPage} />
      <Route exact path="/intro" component={FeatureIntro} />
      <Route exact path="/:token" component={TokenValidator} />
    </Switch>
  );
}

export default App;
