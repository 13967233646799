import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const SideBarGradientCard = () => {
  const { t } = useTranslation();
  return (
    <div
      className="sidebar-card-container is-flex is-flex-direction-column is-justify-content-space-between is-align-items-stretch"
      style={{
        background:
          "linear-gradient(180deg, #F3F3F3, rgba(255, 255, 255, 0.00))",
      }}
    >
      <h1 className="has-text-centered has-text-black is-size-6">
        {t("sidebar.card2.title")}
      </h1>
      <p className="has-text-centered has-text-grey is-size-8">
        {t("sidebar.card2.text")}
      </p>
      <a
        className="is-align-self-center custom-sidebar-gradient-card-button is-size-9"
        href="https://www.ecologie.gouv.fr/feux-foret-et-vegetation"
        target="_blank"
        rel="noreferrer"
      >
        {t("sidebar.card2.buttonText")}
      </a>
    </div>
  );
};

export default SideBarGradientCard;
