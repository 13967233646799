import React, { useEffect, useState } from "react";
import "./index.css";
import { motion as m } from "framer-motion";
// import SideBarDashboard from "../SiderBarDashboard";
import { NavLink } from "react-router-dom";
import SideBar from "../SideBar";
import axa_logo_header from "../../assets/logos/axa_logo_header.png";
import kayrros_logo_header from "../../assets/logos/kayrros_short.png";

const HeaderDashboard = ({runTour, toggleRunTour}: any) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  // const [runTour, setRunTour] = useState<boolean>(runTour)

  useEffect(() => {
    toggleRunTour(runTour)
  }, [runTour, toggleRunTour])

  return (
    <m.div style={{ position: "relative" }}
      className={`px-4 py-5 is-flex is-justify-content-flex-start is-align-items-center is-flex-direction-column ${!showSideBar ? "hamburger-icon-container" : "sidebar-dashboard"
        }`}
      animate={{ width: !showSideBar ? "86px" : "262px" }}
      transition={{ duration: 0.4 }}
    >
      {!showSideBar && (
        <React.Fragment>
        <span
          className="is-clickable close-sidebar-icon is-flex is-justify-content-center is-align-items-center"
          onClick={() => {
            setShowSideBar(true);
          }}
        >
          <i className="ri-arrow-right-s-line ri-2x"></i>
        </span>
        <div className="is-flex is-align-items-center is-flex-direction-column">
          <a
            href="https://smartservices.axaxl.com/fr/"
            target="_blank"
            rel="noreferrer"
          >
            <figure className="sidebar-logo mb-4">
              <img src={axa_logo_header} alt="Axa Logo" />
            </figure>
          </a>
          <a href="https://www.kayrros.com/" target="_blank" rel="noreferrer">
            <figure className="sidebar-logo">
              <img src={kayrros_logo_header} alt="Kayrros Logo" />
            </figure>
          </a>
        </div>
          {/* <span
            className="icon is-medium is-clickable"
            onClick={() => setShowSideBar(!showSideBar)}
            style={{ color: "#26292C" }}
          >
            <i className="ri-menu-line ri-2x hamburger-icon"></i>
          </span> */}
          <div className="is-flex is-flex-direction-column icons-container">
            <div className="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-align-items-stretch">
              <NavLink
                to="/"
              >
                <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                  <i className="ri-home-4-line ri-2x"></i>
                </span>
              </NavLink>
              <NavLink
                to="/search-details"
              >
                <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#000089" }}>
                  <i className="ri-dashboard-line ri-2x"></i>
                </span>
              </NavLink>
              <a
                href="https://smartservices.axaxl.com/"
              >
                <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                  <i className="ri-tv-2-line ri-2x"></i>
                </span>
              </a>
            </div>

            <div className="empty-midspace is-flex is-flex-direction-column is-justify-content-space-between is-align-items-stretch"></div>

            <div className="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-flex-end is-align-items-stretch">
              <div>
                <span onClick={toggleRunTour} className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                  <i className={`${runTour ? "ri-discuss-fill selected-icon" : "ri-discuss-line"} ri-2x`}></i>
                </span>
              </div>
              {/* <NavLink
                to="/legal"
                className="mb-4"
              >
                <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                  <i className="ri-question-line ri-2x"></i>
                </span>
              </NavLink> */}
              <a
        target="_blank"
          href="/intro"
              >
                <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                <i className="ri-question-line ri-2x"></i>
                </span>
              </a>
              <NavLink
                to="/legal"
              >
                <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                  <i className="ri-information-line ri-2x"></i>
                </span>
              </NavLink>
              <NavLink
                to="/gdpr"
              >
              <span className="icon is-medium is-clickable mt-4" style={{ fontWeight: "normal", color: "#94A3B8" }}>
                <i className="ri-git-repository-private-line ri-2x"></i>
              </span>
              </NavLink>
              
            </div>
          </div>
        </React.Fragment>
      )}
      {showSideBar && (
        <SideBar setShowSideBar={setShowSideBar} 
        runTour={runTour}
          toggleRunTour={toggleRunTour}
          />
      )}
    </m.div>
  );
};

export default HeaderDashboard;
